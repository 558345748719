import { inject, Injectable, OnDestroy } from '@angular/core';
import { UserManager } from './user/services/user-manager.service';
import { CompanyDataService } from './shared/services/company-data.service';
import { combineLatest, concat, of, Subject } from 'rxjs';
import { filter, map, skip, switchMap, takeUntil } from 'rxjs/operators';
import { Company } from './shared/types/company.interface';
import { BudgetDataService } from './dashboard/budget-data/budget-data.service';
import { Budget } from './shared/types/budget.interface';
import { UtilityService } from './shared/services/utility.service';
import { FilterManagementService} from 'app/header-navigation/components/filters/filter-services/filter-management.service';
import { Configuration } from './app.constants';
import { BudgetObjectChange } from './budget-object-details/types/budget-object-change.interface';
import { BudgetObjectDetailsManager } from './budget-object-details/services/budget-object-details-manager.service';
import { AppRoutingService } from './shared/services/app-routing.service';
import { CompanySettingsStorageService } from './company-settings-storage.service';
import { CustomFieldsService } from './budget-object-details/components/custom-fields/custom-field.service';

@Injectable()
export class AppDataLoader implements OnDestroy {
  private readonly userManager = inject(UserManager);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly utilityService = inject(UtilityService);
  private readonly filterManagementService = inject(FilterManagementService);
  private readonly configuration = inject(Configuration);
  private readonly budgetObjectDetailsManager = inject(BudgetObjectDetailsManager);
  private readonly appRoutingService = inject(AppRoutingService);
  private companySettingsStorageService = inject(CompanySettingsStorageService);
  private readonly customFieldsService = inject(CustomFieldsService);

  private companyId: number;
  private selectedBudget: Budget;

  private readonly destroy$ = new Subject<void>();

  private readonly objectLoaderByType = {
    [this.configuration.OBJECT_TYPES.goal]: this.budgetDataService.loadGoals.bind(this.budgetDataService),
    [this.configuration.OBJECT_TYPES.campaign]: this.budgetDataService.loadLightCampaigns.bind(this.budgetDataService),
    [this.configuration.OBJECT_TYPES.program]: this.budgetDataService.loadLightPrograms.bind(this.budgetDataService)
  };

  private reflectSelectedBudgetInLocation = true;

  public static getBudgetToSelect(budgetDataService: BudgetDataService, companyDataService: CompanyDataService, userId: number, budgetList: Budget[]): Budget {
    let storedBudgetId = "";
    let storedBudget = budgetDataService.selectedBudgetStorage.get(userId);
    if(storedBudget) {
      const storedCompanyBudgetMap = JSON.parse(storedBudget)
      const selectedCompany = companyDataService.selectedCompanyStorage.get(userId);
      storedBudgetId = storedCompanyBudgetMap[selectedCompany]
    }
    return budgetList.find(budget => budget.id === budgetDataService.initBudgetId) ||
      budgetList.find(budget => budget.id === parseInt(storedBudgetId), 10) ||
      budgetList[0];
  }

  public init(reflectSelectedBudgetInLocation = true, handleBudgetObjectDetailsChanges = true) {
    this.reflectSelectedBudgetInLocation = reflectSelectedBudgetInLocation;

    this.companyDataService.selectedCompany$
      .pipe(
        filter(cmp => cmp != null),
        takeUntil(this.destroy$)
      )
      .subscribe(company => this.onSelectedCompanyChanged(company));

    this.userManager.currentUserId$
      .pipe(
        switchMap(userId => {
          const budgetList$ =
            this.budgetDataService.budgetListSnapshot?.length ?
              concat(of(this.budgetDataService.budgetListSnapshot), this.budgetDataService.companyBudgetList$) :
              this.budgetDataService.companyBudgetList$;
          return budgetList$.pipe(map(budgetList => [userId, budgetList] as const));
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(([userId, budgetList]) => this.onUpdateBudgetList(userId, budgetList));

    this.budgetDataService.selectedBudget$
      .pipe(takeUntil(this.destroy$))
      .subscribe(selectedBudget => this.onUpdateSelectedBudget(selectedBudget));

    const currentFilterSet$ =
      this.filterManagementService.budgetFiltersInit$.pipe(switchMap(() =>
        this.filterManagementService.currentFilterSet$.pipe(skip(1))
      ));

    combineLatest([currentFilterSet$, this.userManager.currentUserId$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
      ([filterSet, userId]) => {
        if (this.selectedBudget != null) {
          this.filterManagementService.filterSetStorage.save(userId, this.selectedBudget.id, filterSet);
        }
      }
    );

    this.utilityService.cleanUserDataProperty$
      .pipe(takeUntil(this.destroy$))
      .subscribe(userId => {
        if (userId != null && this.selectedBudget != null) {
          this.filterManagementService.filterSetStorage.remove(userId, this.selectedBudget.id);
        }
      });

    if (handleBudgetObjectDetailsChanges) {
      this.budgetObjectDetailsManager.budgetObjectChanged$
        .pipe(takeUntil(this.destroy$))
        .subscribe((change) => this.applyBudgetObjectChange(change));
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private onSelectedCompanyChanged(company: Company) {
    if (company) {
      this.initCompanySettingsInSession(company.id);
      this.companyId = company.id;
      this.budgetDataService.loadCompanyBudgetList(company.id, () => console.log('Failed to load company budgets'));
      this.customFieldsService.fetchCustomFieldStatusForSelectedCompany(this.companyId);
    } else {
      this.companyId = null;
    }
  }

  private initCompanySettingsInSession(companyId: number) {
    const key = this.configuration.PFM_COMPANY_SETTINGS_KEY;
      if(this.companyId) { // Clear the company settings from session storage if the company changes
        this.companySettingsStorageService.removeRowFromSessionStorage(key);
      }
      
      this.companySettingsStorageService.getSettings(key).subscribe(data => {});
  }

  private onUpdateBudgetList(userId: number, budgetList: Budget[]) {
    if (budgetList.length > 0) {
      const budgetToSelect = AppDataLoader.getBudgetToSelect(this.budgetDataService,this.companyDataService, userId, budgetList);
      this.budgetDataService.selectBudget(budgetToSelect.id);
      this.budgetDataService.initBudgetId = null;
    } else {
      this.budgetDataService.setNoBudget();
      this.utilityService.showLoading(false);
    }
  }

  private onUpdateSelectedBudget(selectedBudget: Budget) {
    if (this.selectedBudget && (!selectedBudget || this.selectedBudget.id !== selectedBudget.id)) {
      this.userManager.currentUserId$.subscribe(userId => {
        this.filterManagementService.filterSetStorage.remove(userId, this.selectedBudget.id);
      });
    }
    const budgetChanged = selectedBudget != null && (!this.selectedBudget || this.selectedBudget.id !== selectedBudget.id);
    this.selectedBudget = selectedBudget;
    if (this.selectedBudget != null) {
      this.applySelectedBudget(budgetChanged, selectedBudget);
    }
  }

  private applySelectedBudget(budgetChanged: boolean, selectedBudget: Budget): void {
    if (this.reflectSelectedBudgetInLocation) {
      this.appRoutingService.updateCurrentFiltersInRouting(this.companyId, this.selectedBudget.id, {});
    }

    this.userManager.currentUserId$
      .pipe(takeUntil(this.destroy$))
      .subscribe(userId => {
        this.budgetDataService.selectedBudgetStorage.save(userId, this.selectedBudget.id);
      });

    if (budgetChanged) {
      const errorHandler = (error, message?: string) => {
        if (error && message) {
          error.message = message;
        }
        this.utilityService.handleError(error)
      };

      this.filterManagementService.loadSavedFilters(selectedBudget.id, errorHandler);
      this.budgetDataService.loadBudgetTimeframes(selectedBudget.id, errorHandler);
      this.budgetDataService.loadSegmentGroups(selectedBudget.id, errorHandler);
      this.budgetDataService.loadAvailableBudgetSegments(selectedBudget.id, errorHandler);
      this.budgetDataService.loadBudgetSharedCostRules(this.companyId, selectedBudget.id, errorHandler);
      this.budgetDataService.loadGoals(this.companyId, selectedBudget.id, 'Active', errorHandler);
    }
  }

  private applyBudgetObjectChange(change: BudgetObjectChange) {
    const loader = this.objectLoaderByType[change.objectType];
    if (loader) {
      loader(
        this.companyId,
        this.selectedBudget.id,
        this.configuration.goalStatusNames.active,
        error => this.utilityService.handleError(error)
      );
    }
  }
}
