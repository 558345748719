import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppRoutingService } from '@shared/services/app-routing.service';
import { Budget } from '@shared/types/budget.interface';
import { Campaign } from '@shared/types/campaign.interface';
import { TasksListComponent } from 'app/budget-object-details/components/tasks-list/tasks-list.component';
import { MetricBreakdown } from 'app/budget-object-details/types/metric-breakdown-data.interface';
import { MetricMilestone, MetricMilestones } from 'app/budget-object-details/types/metric-milestone.interface';
import { Subject } from 'rxjs';

@Component({
  selector: 'metric-details-form',
  templateUrl: './metric-details-form.component.html',
  styleUrls: ['./metric-details-form.component.scss']
})
export class MetricDetailsFormComponent implements OnInit {
  @Input() currentMetricState;
  @Input() formData: FormGroup;
  @Input() metricBreakdownData: MetricBreakdown;
  @Input() isCampaignMetricDrawer = true;
  @Input() displayDecimal = false;
  @Input() hasActiveThirdPartyAmounts = false;

  @Output() onStartDateChange = new EventEmitter();
  @Output() syncUnsavedMetricChanges = new EventEmitter();
  @Output() syncIsMilestonesDataInvalid = new EventEmitter();
  @Output() updateTargetValueInBreakdown = new EventEmitter();
  @Output() syncMetricFormValidity = new EventEmitter();
  @Output() syncFormDataValuesWithState = new EventEmitter();

  metricFormData: UntypedFormGroup[] = [];
  isMilestonesDataChanged: boolean;

  private readonly destroy$ = new Subject<void>();
  private readonly reset$ = new Subject<void>();
  @ViewChild(TasksListComponent) tasksList: TasksListComponent;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    public readonly appRoutingService: AppRoutingService,
    private fb: UntypedFormBuilder
  ) {
  }

  ngOnInit(): void {
    this.initUpdateMetricMilestonesForm();
    window.addEventListener('click', (event: any) => {
      const includedContainers = ["chevron-holder", "mat-menu-trigger", "fa-chevron-down", "mat-button-wrapper"];
      const classList = event?.target?.classList;
      classList.value.split(" ").forEach(element => {
        if (includedContainers.findIndex(item => item == element) != -1 && this.isMilestonesDataChanged) {
          this.metricFormData.map(fg => {
            fg.controls.amount.markAsTouched()
            fg.controls.date.markAsTouched()
            fg.controls['amount'].updateValueAndValidity();
            fg.controls['date'].updateValueAndValidity();
          })
        }
      });
    })
  }

  initUpdateMetricMilestonesForm() {
    this.metricFormData = this.currentMetricState?.milestones.map(
      ml => this.createFormGroup(ml.targetValue, ml.date)
    )
    this.sortMilestonesFormDataArray();
  }

  sortMilestonesFormDataArray() {
    this.metricFormData.sort(
      (fg1, fg2) => Number(fg1.controls['date'].value) - Number(fg2.controls['date'].value),
    )
    if (this.checkFormValidity()) {
      this.syncUnsavedMetricChanges.emit();
      this.syncIsMilestonesDataInvalid.emit(false);
    } else {
      this.syncUnsavedMetricChanges.emit(false);
      this.syncIsMilestonesDataInvalid.emit(true);
    }
    this.metricFormData.map(fg => {
      // fg.controls.amount.markAsTouched()
      // fg.controls.date.markAsTouched()
      fg.controls['amount'].updateValueAndValidity();
      fg.controls['date'].updateValueAndValidity();
    })
  }

  createFormGroup(amount, date) {
    const formGroup = this.fb.group({
      amount: [amount || 0, {
        validators: [this.createAmountValidator()],
        updateOn: 'blur'
      }],
      date: [date, {
        validators: [this.createDateValidator()],
        updateOn: 'blur'
      }]
    })
    return formGroup;
  }

  createDateValidator(): ValidatorFn {
    return (date: UntypedFormControl): ValidationErrors => {
      const formGroup = date.parent as UntypedFormGroup;

      if (this.metricFormData.length < 2) {
        return null;
      }

      if (!date.value) {
        return { required: true }
      }

      if (!this.isUniqueDateValue(formGroup)) {
        return { unique: true }
      }

      if (!this.isAmountAndDateMatch(formGroup)) {
        return { match: true }
      }

      return null
    }
  }

  isUniqueAmountValue(formGroup: UntypedFormGroup): boolean {
    return !this.metricFormData.some(
      fd => fd !== formGroup && +fd.controls['amount'].value === +formGroup.controls['amount'].value
    )
  }

  isUniqueDateValue(formGroup: UntypedFormGroup): boolean {
    return !this.metricFormData.some(
      fd => {
        const currentDate = fd.value['date'];
        if (!currentDate) {
          return false;
        }
        const selectedDate = formGroup?.controls['date']?.value?.toLocaleDateString();
        return fd !== formGroup && currentDate.toLocaleDateString() === selectedDate;
      }
    )
  }

  isAmountAndDateMatch(formGroup: UntypedFormGroup): boolean {
    if (!formGroup) {
      return false;
    }
    const { date, amount } = formGroup.controls;
    const milestones: MetricMilestones = this.getMilestonesSnapshot(true).filter(el => el.targetValue !== amount.value);
    let isMatch = true;
    if (!milestones.length) {
      return isMatch;
    }
    const lastIndex = milestones.length - 1;
    const biggerDateIndex = milestones.findIndex((fd) => fd.date.getTime() > date.value.getTime());
    const expectedIndex = biggerDateIndex < 0 ? milestones.length : biggerDateIndex;

    if (expectedIndex === 0) {
      isMatch = amount.value < milestones[0].targetValue;
    } else if (expectedIndex > lastIndex) {
      isMatch = amount.value > milestones[lastIndex].targetValue
    } else {
      isMatch = amount.value > milestones[expectedIndex - 1].targetValue && amount.value < milestones[biggerDateIndex].targetValue
    }
    return isMatch;
  }

  getMilestonesSnapshot(onlyValid?: boolean): MetricMilestone[] {
    return (onlyValid
      ? this.metricFormData.filter(fd => fd.valid && fd.value['date'])
      : this.metricFormData)
      .map(fd => {
        const { amount, date } = fd.controls;
        return {
          targetValue: amount.value,
          date: date.value
        }
      })
  }

  createAmountValidator(): ValidatorFn {
    return (amount: UntypedFormControl): ValidationErrors => {
      const formGroup = amount.parent as UntypedFormGroup;
      const date = formGroup ? formGroup.value['date'] : null;
      if (this.metricFormData.length < 2 || !date) {
        return null;
      }

      if (!amount.value) {
        return { required: true }
      }

      if (!this.isUniqueAmountValue(formGroup)) {
        return { unique: true }
      }

      if (!this.isAmountAndDateMatch(formGroup)) {
        return { match: true }
      }

      return null
    }
  }

  deleteMilestone({ event, index }) {
    event.stopPropagation();
    this.isMilestonesDataChanged = true;
    this.currentMetricState.milestones.splice(index, 1);
    this.metricFormData.splice(index, 1);
    // this.initUpdateMetricMilestonesForm();
    this.handleMilestoneValueUpdate();
    this.syncUnsavedMetricChanges.emit();
    this.syncUnsavedChanges();
    this.updateTargetValueInBreakdown.emit();
  }

  addMilestone(event) {
    event.stopPropagation();
    this.isMilestonesDataChanged = true;
    this.currentMetricState.milestones.unshift({ targetValue: null, date: '' });
    this.metricFormData.unshift(this.createFormGroup(null, ''))
    // this.initUpdateMetricMilestonesForm();
    this.handleMilestoneValueUpdate();
    setTimeout(() => {
      this.syncUnsavedChanges();
    }, 10);
  }

  syncUnsavedChanges() {
    if (this.checkFormValidity()) {
      this.syncUnsavedMetricChanges.emit();
      this.syncIsMilestonesDataInvalid.emit(false);
      this.syncMetricFormValidity.emit(true);
    } else {
      this.syncUnsavedMetricChanges.emit(false);
      this.syncIsMilestonesDataInvalid.emit(true);
      this.syncMetricFormValidity.emit(false);
    }
  }

  checkFormValidity(): boolean {
    const arr = this.metricFormData.filter(ms => ms.status === 'INVALID');
    const milestonesFormValues = this.metricFormData.map(item => item.getRawValue())
    if (milestonesFormValues[milestonesFormValues.length - 1].amount == 0 && this.isMilestonesDataChanged) {
      this.metricFormData[this.metricFormData.length - 1].controls.amount.markAsTouched()
      this.metricFormData[this.metricFormData.length - 1].controls.date.markAsTouched()
    }
    if (arr.length > 0) {
      return false;
    }
    return true;
  }

  handleMilestoneValueUpdate() {
    this.metricFormData.map(fg => {
      if (fg.controls.amount.value != 0 && typeof fg.controls.date.value === "object") {
        fg.controls.amount.markAsTouched()
        fg.controls.date.markAsTouched()
        fg.controls['amount'].updateValueAndValidity();
        fg.controls['date'].updateValueAndValidity();
      }
    })
    setTimeout(() => {
      if (this.checkFormValidity()) {
        this.sortMilestonesFormDataArray();
        this.syncFormDataValuesWithState.emit(this.metricFormData);
        this.updateTargetValueInBreakdown.emit();
      }
    }, 10);
  }

  handleMilestonesUpdate(index) {
    this.updateTargetValueInBreakdown.emit();
    if (this.checkFormValidity()) {
      this.syncIsMilestonesDataInvalid.emit(false);
      this.syncMetricFormValidity.emit(true);
      this.syncUnsavedMetricChanges.emit();
    } else {
      this.syncIsMilestonesDataInvalid.emit(true);
      this.syncUnsavedMetricChanges.emit(false);
      this.syncMetricFormValidity.emit(false);
    }
  }

  onHandleStartDateChange(event) {
    this.onStartDateChange.emit(event);
    setTimeout(() => {
      this.syncUnsavedChanges();
    }, 10);
    // this.syncMetricFormValidity.emit(true);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
