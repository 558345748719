/**
 * Import Angular Core Libraries
 */
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe, DecimalPipe, CurrencyPipe, TitleCasePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!

/**
 * Import Angular Custom Modules
 */
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-modialog-7';
import { bootstrap3Mode, BootstrapModalModule } from 'ngx-modialog-7/plugins/bootstrap';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { Ng2CompleterModule } from 'ng2-completer';
import { DndModule } from 'ng2-dnd';

/**
 * Import Routing, Constants files
 */
import { Routing } from './app.routes';

/**
 * Import Directives, Pipes
 */
import { FirstZeroHideDirective } from '@shared/directives/first-zero-hide.directive';
import { IsSharedSubObjectPipe } from './dashboard/is-shared-subobject.pipe';

/**
 * Import Components
 */
import { AppComponent } from './app.component';
import { Dashboard } from './dashboard/dashboard.component';
import { Widget } from './dashboard/widget.component';
import { PlanSetting } from './quick-start/plansetting.component';
import { SearchComponent } from './search/search.component';
import { WidgetContainerComponent } from './dashboard/widget-container/widget-container.component';
import { BudgetTimelineTableComponent } from './dashboard/budget-timeline-table/budget-timeline-table.component';
import { BudgetHierarchyTableComponent } from './dashboard/budget-hierarchy-table/budget-hierarchy-table.component';
import { SpendByTypeTableComponent } from './dashboard/spend-by-type-table/spend-by-type-table.component';
import { TableViewComponent } from 'app/shared/components/table-view/table-view.component';
import { MarketingCalendarComponent } from './marketing-calendar/components/marketing-calendar/marketing-calendar.component';
import { ChildObjectsListComponent } from './dashboard/child-objects-list/child-objects-list.component';
import { ExpenseTypesComponent } from './account/expense-types/expense-types.component';
import { GlCodesComponent } from './account/gl-codes/gl-codes.component';
import { UnsubscribePageComponent } from './unsubscribe-page/unsubscribe-page.component';

/**
 * Import Services
 */
import { PlanSettingService } from './quick-start/plansetting.service';
import { WidgetService } from './dashboard/widget.service';
import { SearchService } from './search/search.service';
import { ExportDataService } from './dashboard/export-data.service';
import { BudgetDataService } from './dashboard/budget-data/budget-data.service';

/**
 * Import Modules
 */
import { SharedModule } from '@shared/shared.module';
import { ExchangeRatesModule } from './account/exchange-rates/exchange-rates.module';
import { TagsManagementModule } from './account/tags-management/tags-management.module';
import { SharedCostRulesManagementModule } from './account/shared-cost-rule/shared-cost-rules.module';
import { BudgetObjectDetailsModule } from './budget-object-details/budget-object-details.module';
import { MarketingCalendarModule } from './marketing-calendar/marketing-calendar.module';
import { MetricIntegrationsModule } from './metric-integrations/metric-integrations.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HomeModule } from './home/home.module';
import { HeaderNavigationModule } from './header-navigation/header-navigation.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { TitleDescriptionTooltipComponent } from '@shared/components/title-description-tooltip/title-description-tooltip.component';
import { BudgetSettingsModule } from './budget-settings/budget-settings.module';
import { TimelineChartTooltipDirective } from './dashboard/timeline-chart-tooltip.directive';
import { UserModule } from './user/user.module';
import { SuperAdminModule } from '../super-admin-app/super-admin.module';
import { ExpensesModule } from '@spending/expenses.module';
import { TeamPageModule } from './account/team-page/team-page.module';
import { RootLoaderComponent } from './root-loader/root-loader.component';
import { ImportDataComponent } from './import-data/import-data.component';
import { MatTabsModule } from '@angular/material/tabs';
import { BudgetAllocationModule } from './budget-allocation/budget-allocation.module';
import { ManageTableModule } from './manage-table/manage-table.module';
import { ManageCegTableModule } from './manage-ceg-table/manage-ceg-table.module';
import { ReportsExportComponent } from './import-data/reports-export/reports-export.component';
import { MetricFunnelsModule } from './metric-funnels/metric-funnels.module';
import { ToastrModule } from 'ngx-toastr';
import { FunnelPresentationModule } from '@common-lib/lib/funnel-presentation/funnel-presentation.module';
import { ImportingInfoDialogComponent } from './import-data/importing-info-dialog/importing-info-dialog.component';
import { CommonHeadersInterceptor } from '@common-lib/lib/http/http-common-headers.interceptor';
import { AppConfig } from './app-config.interface';
import { AppConfigService } from '@common-lib/services/app-config.service';
import { tap } from 'rxjs/operators';
import { AnalyticsService } from '@common-lib/services/analytics.service';
import { UserManager } from './user/services/user-manager.service';
import { AuthInterceptor } from './user/services/http-auth.interceptor';
import { API_V0_URL, API_V2_URL, API_V3_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { AmplifyService } from './user/services/amplify.service';
import { ExportBudgetSettingsComponent } from './import-data/export-budget-settings/export-budget-settings.component';
import { AttributesAndTagsComponent } from './account/attributes-tags/attributes-tags.component';
import { CustomFieldsComponent } from './account/custom-fields/custom-fields.component';

const APP_CONFIG_FILE_PATH = 'appConfig.json';

const appInitializerFn =
  () => {
    const appConfigService: AppConfigService<AppConfig> = inject(AppConfigService);
    const amplifyService = inject(AmplifyService);
    return () => {
      bootstrap3Mode();
      return appConfigService.loadAppConfig(APP_CONFIG_FILE_PATH).pipe(
        tap(appConfig => {
          amplifyService.configureAmplify(appConfig.auth_data);
          AnalyticsService.initAnalytics(appConfig);
        })
      );
    };
  };

@NgModule({
  declarations: [
    AppComponent,
    Dashboard,
    Widget,
    PlanSetting,
    FirstZeroHideDirective,
    SearchComponent,
    WidgetContainerComponent,
    BudgetTimelineTableComponent,
    BudgetHierarchyTableComponent,
    SpendByTypeTableComponent,
    TableViewComponent,
    MarketingCalendarComponent,
    ChildObjectsListComponent,
    IsSharedSubObjectPipe,
    UnsubscribePageComponent,
    ExpenseTypesComponent,
    GlCodesComponent,
    TitleDescriptionTooltipComponent,
    RootLoaderComponent,
    ImportDataComponent,
    ReportsExportComponent,
    ImportingInfoDialogComponent,
    ExportBudgetSettingsComponent,
    AttributesAndTagsComponent,
    CustomFieldsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    Ng2CompleterModule,
    Ng2GoogleChartsModule,
    FormsModule,
    Routing,
    TooltipModule.forRoot(),
    InfiniteScrollModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    BootstrapModalModule,
    BrowserAnimationsModule,
    DndModule.forRoot(),
    ExpensesModule,
    BudgetAllocationModule,
    SharedModule,
    ExchangeRatesModule,
    TagsManagementModule,
    SharedCostRulesManagementModule,
    BudgetObjectDetailsModule,
    MarketingCalendarModule,
    MetricIntegrationsModule,
    FontAwesomeModule,
    MatTooltipModule,
    MatButtonModule,
    HomeModule,
    MatMenuModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    HeaderNavigationModule,
    MatDialogModule,
    BudgetSettingsModule,
    UserModule,
    TeamPageModule,
    SuperAdminModule,
    MatTabsModule,
    ManageTableModule,
    ManageCegTableModule,
    MetricFunnelsModule,
    FunnelPresentationModule,
    ToastrModule.forRoot({
      maxOpened: 3,
      autoDismiss: true,
      preventDuplicates: true
    }),
  ],
  providers: [
    AmplifyService,
    Title,
    DatePipe,
    DecimalPipe,
    PlanSettingService,
    WidgetService,
    ExportDataService,
    SearchService,
    CurrencyPipe,
    BudgetDataService,
    {
      provide: API_V0_URL,
      useFactory: () => {
        const appConfigService: AppConfigService<AppConfig> = inject(AppConfigService);
        return appConfigService.config?.server_url;
      }
    },
    {
      provide: API_V2_URL,
      useFactory: () => {
        const appConfigService: AppConfigService<AppConfig> = inject(AppConfigService);
        return appConfigService.config?.api_v2_url;
      }
    },
    {
      provide: API_V3_URL,
      useFactory: () => {
        const appConfigService: AppConfigService<AppConfig> = inject(AppConfigService);
        return appConfigService.config?.api_v3_url;
      }
    },
    {
      provide: AppConfigService,
      useFactory: () => new AppConfigService<AppConfig>()
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [UserManager]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CommonHeadersInterceptor,
      multi: true
    },
    IsSharedSubObjectPipe,
    TitleCasePipe
  ],
  bootstrap: [AppComponent],
  exports: [BrowserModule]
})
export class AppModule { }
