import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { WidgetConfig, WidgetType } from '../../types/widget.interface';
import { WidgetConfigService } from '../../services/widget-config.service';

@Component({
  selector: 'plan-highlights',
  styleUrls: ['./plan-highlights.component.scss'],
  templateUrl: './plan-highlights.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanHighlightsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  public displayTitle = true;
  public title = 'Summary plan highlights';
  public widgets: WidgetConfig[] = [
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.ESTIMATED_BUSINESS_VALUE],
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.BUDGET_MINUS_EXPENSES],
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.BUDGET_SPENT],
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.SPEND_PROGRESS],
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.CAMPAIGN_SPENT],
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.METRICS_PROGRESS],
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.CAMPAIGNS],
  ];

  constructor(
    private readonly widgetConfig: WidgetConfigService
  ) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}


