import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalContext } from '../../shared/components/modal-with-icon-header/modal-with-icon-header.component';
import { IconHeaderModalService } from '../../shared/services/modal-wit-icon-header.service';
import { BudgetSelectionModalService, BudgetSelectionContext, BudgetGroup } from '../../shared/services/budget-selection-modal.service';
import { BehaviorSubject, catchError, finalize, of, Subject, Subscription, take, takeUntil } from 'rxjs';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { AppRoutingService } from '../../shared/services/app-routing.service';
import { Router } from '@angular/router';
import { BackNavigationContext } from '../../manage-table/services/manage-page.service';
import { FilterName } from '../../header-navigation/components/filters/filters.interface';
import { HierarchyViewMode } from '../../expenses/types/expense-page.type';
import { UtilityService } from '../../shared/services/utility.service';

@Component({
  selector: 'budget-selection-attributes-tags',
  templateUrl: './budget-selection-attributes-tags.component.html',
  styleUrls: ['./budget-selection-attributes-tags.component.scss']
})
export class BudgetSelectionComponent implements OnInit {
  title: string = 'Select Budget';
  modalContent: string;
  modalIconName: string;
  modalIconPrefix: string;
  buttons: any;
  currentBudgetId: number;
  selectedBudget: BudgetGroup | null = null;
  selectedItemId: number;

  @ViewChild('SelectionTemplate') SelectionTemplate!: TemplateRef<any>;


  modalData!: ModalContext;
  subscription!: Subscription;
  budgetData: BudgetSelectionContext;
  destroy$ = new Subject<void>();

  constructor(
    public modalService: IconHeaderModalService,
    private dialogService: BudgetSelectionModalService,
    private router: Router,
    private readonly budgetDataService: BudgetDataService,
    private readonly appRoutingService: AppRoutingService,
    public readonly utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.dialogService.openDialog$
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data) {
          this.title = data.title;
          this.modalContent = data.content;
          this.modalIconName = data.icon.name;
          this.modalIconPrefix = data.icon.prefix;
          this.buttons = data.buttons;
          this.budgetData = data;
          this.currentBudgetId = data.currentBudgetId;
          this.initializeModalData();
          this.openBudgetSelectionDialog(data);
          this.selectedItemId = data.selectedItemId;
          this.selectedBudget = this.budgetData.groups?.[0] || null;
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  initializeModalData(): void {
    this.modalData = {
      title: this.title,
      content: this.modalContent,
      icon: {
        prefix: this.modalIconPrefix,
        name: this.modalIconName
      },
      buttons: this.buttons
    };
  }

  handleBudgetRedirection(e: BudgetGroup): void {
    console.log('Budget redirection handled - ' + JSON.stringify(e));
    this.selectedBudget = e;
    this.modalData.buttons[0].disabled = false;
  }


  openBudgetSelectionDialog(data: BudgetSelectionContext): void {
    this.utilityService.showLoading(true);
    this.dialogService.openDialogSource.next(null);
    this.modalService.openInitialUpdatePopup(this.modalData, data.groups.length > 1 ? this.SelectionTemplate : null, false, 'dropdownWithRightButton', true)
      .pipe(
        finalize(() => this.utilityService.showLoading(false)),
      )
      .subscribe((result) => {
        if (result) {
          this.handleSwitchBudget(data);
        }
      });
  }

  handleSwitchBudget(data: BudgetSelectionContext): void {
    const switchBudget$ = new BehaviorSubject<BudgetGroup | null>(null);

    if (this.selectedBudget?.budget_id !== this.currentBudgetId) {
      this.budgetDataService.selectBudget(this.selectedBudget.budget_id, () => {
        switchBudget$.next(this.selectedBudget);
      });
    } else {
      switchBudget$.next(this.selectedBudget);
    }

    switchBudget$.pipe(
      take(1),
      takeUntil(this.destroy$),
      catchError((error) => {
        this.utilityService.handleError(error);
        return of(null);
      })
    ).subscribe((selectedBudget: BudgetGroup) => {
      if (selectedBudget) {
        this.navigateToExpensePage(data);
      }
    });
  }


  navigateToExpensePage(data: BudgetSelectionContext): void {
    console.log(data);
    const encodedMode = data.selectedTab;
    const pageContext: BackNavigationContext = {
      pageName: 'Attributes & Tags',
      route: `${this.router.routerState.snapshot.url.split('?')[0]}`,
    };
    if (data.selectedTab === 'groupTypes') {
      const filters = { [FilterName.CampaignTypes]: [this.selectedItemId] };
      this.appRoutingService.openManageCEGPageFromAdminPage(filters, pageContext, data.selectedTab);
    } else if (data.selectedTab === 'goalTypes') {
      const filters = { [FilterName.GoalTypes]: [this.selectedItemId] };
      this.appRoutingService.openManageCEGPageFromAdminPage(filters, pageContext, data.selectedTab);
    }
    else {
      const filters = { [FilterName.ExpenseTypes]: [this.selectedItemId] };
      this.appRoutingService.openExpenseListFromAdminTagsPage(filters, pageContext, HierarchyViewMode.Segment, data.selectedTab);
    }

  }
}
