import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { WidgetConfig, WidgetState } from '../../types/widget.interface';
import { WidgetStateService } from '../../services/widget-state.service';
import { HistoryItem, HistoryService } from 'app/shared/services/history.service';
import { HomePageService } from '../../services/home-page.service';
import { ExpenseDO } from 'app/shared/types/expense.interface';
import { HistoryObjectLogTypeNames } from 'app/shared/types/history-object-log-type.type';
import { Campaign } from 'app/shared/types/campaign.interface';
import { Program } from 'app/shared/types/program.interface';
import { Goal } from 'app/shared/types/goal.interface';
import { MetricMappingDO, MetricService } from 'app/shared/services/backend/metric.service';
import { Company } from 'app/shared/types/company.interface';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { Configuration } from 'app/app.constants';

@Component({
  selector: 'history-widget',
  styleUrls: ['./history-widget.component.scss'],
  templateUrl: './history-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoryWidgetComponent implements OnInit, OnDestroy {
  @Input() config: WidgetConfig;

  private readonly destroy$ = new Subject<void>();
  public state = WidgetState.INITIAL;
  public widgetState = WidgetState;
  public historyData: HistoryItem[] = [];

  private company: Company = null;
  private goals: Map<number, Goal> = null;
  private campaigns: Map<number, Campaign> = null;
  private programs: Map<number, Program> = null;
  private expenses: Map<number, ExpenseDO> = null;
  private metricMappings: Map<number, MetricMappingDO> = null;
  public updatedDates = new Map<string, Date>();

  constructor(
    private readonly widgetStateManager: WidgetStateService,
    private readonly historyService: HistoryService,
    private readonly homePageService: HomePageService,
    private readonly metricService: MetricService,
    private readonly companyDataService: CompanyDataService,
    private readonly configuration: Configuration,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.setState(WidgetState.LOADING);
    this.homePageService.noBudgets$
      .pipe(
        takeUntil(this.destroy$),
        take(1)
      )
      .subscribe(
        () => { this.setState(WidgetState.EMPTY); }
      );

    this.loadData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private loadData() {
    this.historyService.history$
      .pipe(
        filter(items => items != null),
        tap((items) => {
          this.setState(WidgetState.LOADING);
          this.historyData = items;
          this.company = this.companyDataService.selectedCompanySnapshot;
          this.updatedDates.clear();
        }),
        switchMap(() => {
          const maps = this.historyData.filter(item => item.object.type === HistoryObjectLogTypeNames.metricMapping);
          return this.homePageService.loadMetricMappings(this.company.id, maps.map(i => i.object.id));
        })
      )
      .subscribe(() => {});

    const objectTypes = this.configuration.OBJECT_TYPES;
    const expenses$ = this.homePageService.getObjectMap(objectTypes.expense)
      .pipe(
        tap((expenses) => {
          this.expenses = expenses as Map<number, ExpenseDO>;
        })
      );

    const campaigns$ = this.homePageService.getObjectMap(objectTypes.campaign)
      .pipe(
        tap((campaigns) => {
          this.campaigns = campaigns as Map<number, Campaign>;
        })
      );

    const goals$ = this.homePageService.getObjectMap(objectTypes.goal)
      .pipe(
        tap((goals) => {
          this.goals = goals as Map<number, Goal>;
        })
      );

    const programs$ = this.homePageService.getObjectMap(objectTypes.program)
      .pipe(
        tap((programs) => {
          this.programs = programs as Map<number, Program>;
        })
      );

    const mappings$ = this.homePageService.getObjectMap(objectTypes.metric)
      .pipe(
        tap((mappings) => {
          this.metricMappings = mappings as Map<number, MetricMappingDO>;
        })
      );

    combineLatest([
      expenses$,
      programs$,
      campaigns$,
      goals$,
      mappings$
    ]).pipe(
      takeUntil(this.destroy$),
      debounceTime(800)
    ).subscribe(
      () => this.prepareUpdatedDates()
    );
  }

  private getUpdatedDate(item, type): Date {
    if (!item) {
      return null;
    }

    switch (type) {
      case HistoryObjectLogTypeNames.goal:
      case HistoryObjectLogTypeNames.campaign:
      case HistoryObjectLogTypeNames.program:
        return item.updatedDate;

      case HistoryObjectLogTypeNames.expense:
      case HistoryObjectLogTypeNames.metricMapping:
        return new Date(item.upd);

      default:
        return null;
    }
  }

  private prepareUpdatedDates() {
    const isDataReady = this.expenses && this.goals && this.campaigns && this.programs && this.metricMappings && this.historyData;
    if (!isDataReady) {
      return;
    }

    const mapByType = {
      [HistoryObjectLogTypeNames.goal]: this.goals,
      [HistoryObjectLogTypeNames.campaign]: this.campaigns,
      [HistoryObjectLogTypeNames.program]: this.programs,
      [HistoryObjectLogTypeNames.expense]: this.expenses,
      [HistoryObjectLogTypeNames.metricMapping]: this.metricMappings,
    };

    this.historyData.forEach(item => {
      const { object: { type, id: objectId } } = item;
      const objectMap = mapByType[type];
      if (!objectMap) {
        return;
      }

      const target = objectMap.get(objectId);
      const updatedDate = this.getUpdatedDate(target, type);
      if (updatedDate) {
        this.updatedDates.set(`${type}_${target.id}`, updatedDate);
      }
    });

    this.setState(WidgetState.READY);
  }

  private setState(state) {
    this.state = state;
    this.widgetStateManager.setState(this.state, this.config);
    this.cdRef.detectChanges();
  }
}
