import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { WidgetConfig, WidgetType } from '../../types/widget.interface';
import { WidgetConfigService } from '../../services/widget-config.service';

//import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';

@Component({
  selector: 'priority-actions',
  styleUrls: ['./priority-actions.component.scss'],
  templateUrl: './priority-actions.component.html'
})
export class PriorityActionsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  //private readonly budgetDataService = inject(BudgetDataService);
  public displayTitle = true;
  public title = 'My Actions';
  public widgets: WidgetConfig[] = [
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.OPEN_TASKS],
//    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.OVERDUE_EXPENSES],
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.UPDATE_METRICS],
    this.widgetConfig.CONFIG_BY_TYPE[WidgetType.ASSIGN_SEGMENTS_GOOGLE_CAMPAIGNS]
  ];

  constructor(
    private readonly widgetConfig: WidgetConfigService
  ) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}


