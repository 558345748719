import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import { UtilityService } from 'app/shared/services/utility.service';
import { Budget } from 'app/shared/types/budget.interface';
import { Company } from 'app/shared/types/company.interface';
import { WidgetConfig, WidgetState } from '../../types/widget.interface';
import { WidgetStateService } from '../../services/widget-state.service';
import { Currency } from 'app/shared/types/currency.interface';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { ExpensesService } from 'app/shared/services/backend/expenses.service';
import { ExpenseDO } from 'app/shared/types/expense.interface';
import { BudgetAllocationService } from 'app/shared/services/backend/budget-allocation.service';
import { defineTimeframe, getTodayFixedDate } from 'app/shared/utils/budget.utils';
import { ExpenseAllocationMode } from 'app/shared/types/expense-allocation-mode.type';
import {
  OverdueExpensesChangeAction,
  OverdueExpensesTableChange,
  OverdueExpensesTableItem
} from '../../components/overdue-expenses-table/overdue-expenses-table.component';
import { BudgetObjectDetailsManager } from 'app/budget-object-details/services/budget-object-details-manager.service';
import { CurrencyService } from 'app/shared/services/backend/currency.service';
import { HomePageService } from '../../services/home-page.service';
import { HomePageEventService } from '../../services/home-page-event.service';
import { HomePageEventType } from '../../types/home-page-event.type';
import { BudgetObjectType } from 'app/shared/types/budget-object-type.interface';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { BudgetObjectService } from 'app/shared/services/budget-object.service';
import { AllocationMode } from 'app/shared/types/budget-object-allocation.interface';
import { UserManager } from '../../../user/services/user-manager.service';

@Component({
  selector: 'overdue-expenses-widget',
  styleUrls: ['./overdue-expenses-widget.component.scss'],
  templateUrl: './overdue-expenses-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverdueExpensesWidgetComponent implements OnInit, OnDestroy {
  @Input() config: WidgetConfig;
  @Output() onLoaded = new EventEmitter();

  private readonly destroy$ = new Subject<void>();
  private SNACKBAR_DURATION = 1500;
  private messageGetters = {
    itemClosed: (mode) => `${mode} expense was closed`,
    itemsClosed: (mode) => `All ${mode} expenses were closed`,
    itemMoved: (name, mode) => `${mode} expense was moved to ${name}`,
    itemsMoved: (name, mode) => `All ${mode} expenses were moved to ${name}`,
    amountChanged: () => 'Allocation amount has been changed'
  };
  private actionHandlers = {
    [OverdueExpensesChangeAction.AmountChanged]: ($event) => this.updateAmount($event),
    [OverdueExpensesChangeAction.CloseAtPlanned]: ($event) => this.closeItems($event),
    [OverdueExpensesChangeAction.CloseAtCommitted]: ($event) => this.closeItems($event),
    [OverdueExpensesChangeAction.CloseAtZero]: ($event) => this.closeItems($event),
    [OverdueExpensesChangeAction.MoveItem]: ($event) => this.moveItems($event),
    [OverdueExpensesChangeAction.MoveAll]: ($event) => this.moveItems($event),
  };
  private expenseTypes: BudgetObjectType[] = [];
  private contextChanged = false;

  public rawExpenses: ExpenseDO[] = [];
  public overdueExpenses: ExpenseDO[] = [];
  public tableData: OverdueExpensesTableItem[] = [];
  public currentBudget: Budget = null;
  public company: Company;
  public currencyList: Currency[] = [];
  public timeframesList: BudgetTimeframe[] = [];
  public currentTimeframe: BudgetTimeframe;
  public overdueTimeframes: BudgetTimeframe[] = [];
  public relevantTimeframes: BudgetTimeframe[] = [];
  public currency: Currency;
  public currencyMap = new Map<string, Currency>();
  public state = WidgetState.INITIAL;
  public widgetState = WidgetState;
  public activeCounter = 0;
  protected innerDataLoading = false;

  private static getCloseAllocationPayload(action: OverdueExpensesChangeAction, item: OverdueExpensesTableItem): Partial<ExpenseDO> {
    const payload: Partial<ExpenseDO> = {
      mode: ExpenseAllocationMode.Closed
    };

    switch (action) {
      case OverdueExpensesChangeAction.CloseAtZero:
        payload.source_actual_amount = 0;
        break;
      case OverdueExpensesChangeAction.CloseAtCommitted:
        payload.source_actual_amount = item.sourceActualAmount;
        break;
      case OverdueExpensesChangeAction.CloseAtPlanned:
        payload.source_actual_amount = item.sourceAmount;
        break;
    }

    return payload;
  }

  constructor(
    private readonly widgetStateManager: WidgetStateService,
    private readonly homePageService: HomePageService,
    private readonly homePageEventService: HomePageEventService,
    private readonly utilityService: UtilityService,
    private readonly expensesService: ExpensesService,
    private readonly budgetAllocationService: BudgetAllocationService,
    private readonly budgetObjectManager: BudgetObjectDetailsManager,
    private readonly companyDataService: CompanyDataService,
    private readonly currencyService: CurrencyService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly userManager: UserManager,
  ) {}

  ngOnInit(): void {
    this.setState(WidgetState.LOADING);
    this.loadContextData();
    this.homePageService.noBudgets$
      .pipe(
        takeUntil(this.destroy$),
        take(1)
      )
      .subscribe(
        () => {
          this.setState(WidgetState.HIDDEN);
          this.destroy$.next();
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setState(state) {
    this.state = state;
    this.widgetStateManager.setState(this.state, this.config);
    this.cdRef.detectChanges();
  }

  private handleError(err) {
    this.utilityService.handleError(err);
  }

  private prepareTableData(expenses: ExpenseDO[], timeframes: BudgetTimeframe[]): OverdueExpensesTableItem[] {
    const overdueTimeframeIds = this.overdueTimeframes.map(tf => tf.id);
    const integrationExpenseTypeIds = BudgetObjectService.getExternalIntegrationTypeIds(this.expenseTypes);

    return expenses
      .filter(expense => overdueTimeframeIds.includes(expense.company_budget_alloc))
      .map(expense => {
        const freeTimeframes =
          this.timeframesList.filter(
            tf => tf.id !== expense.company_budget_alloc && !overdueTimeframeIds.includes(tf.id)
          );
        const isReadOnly = integrationExpenseTypeIds.includes(expense.expense_type);
        const timeframe = timeframes.find(tf => tf.id === expense.company_budget_alloc);
        return {
          id: `${expense.id}_${expense.company_budget_alloc}`,
          expenseId: expense.id,
          name: expense.name,
          companyBudgetAlloc: expense.company_budget_alloc,
          allocationName: timeframe.name,
          mode: expense.mode as AllocationMode,
          sourceAmount: expense.source_amount,
          amount: expense.amount,
          actualAmount: expense.actual_amount,
          sourceActualAmount: expense.source_actual_amount,
          currency: expense.source_currency,
          freeTimeframes: freeTimeframes,
          isReadOnly
        };
      });
  }

  private getOverdueExpenses(expenses: ExpenseDO[]): ExpenseDO[] {
    const overdueTimeframeIds = this.overdueTimeframes.map(tf => tf.id);
    return expenses?.filter(expense =>
      overdueTimeframeIds.includes(expense.company_budget_alloc) &&
      [ExpenseAllocationMode.Planned.toString(), ExpenseAllocationMode.Committed.toString()].includes(expense.mode)
    );
  }

  private setTimeframesData() {
    const overdueTFs = [];
    const relevantTFs = [];
    const fixedDate = getTodayFixedDate(this.currentBudget);
    this.currentTimeframe = defineTimeframe(this.timeframesList, this.currentBudget, fixedDate);

    let currentTimeframeReached = false;
    this.timeframesList.forEach(tf => {
      if (!currentTimeframeReached) {
        currentTimeframeReached = this.currentTimeframe && this.currentTimeframe.id === tf.id;
      }
      if (!currentTimeframeReached) {
        overdueTFs.push(tf);
      } else {
        relevantTFs.push(tf);
      }
    });

    this.overdueTimeframes = overdueTFs;
    this.relevantTimeframes = relevantTFs;
  }

  private resetData() {
    this.currentTimeframe = null;
    this.relevantTimeframes = [];
    this.overdueTimeframes = [];
    this.tableData = [];
  }

  private prepareData() {
    this.resetData();
    this.setTimeframesData();
    this.refreshTableData();
    setTimeout(() => {
      this.setState(this.rawExpenses.length && this.tableData.length ? WidgetState.READY : WidgetState.HIDDEN);
    });
  }

  public loadContextData() {
    this.currencyService.currencyList$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currencyList => {
        this.currencyList = currencyList;
        currencyList.forEach(currency => {
          this.currencyMap.set(currency.code, currency);
        })
      });

    this.companyDataService.expenseTypeList$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (expenseTypes) => {
          this.expenseTypes = expenseTypes;
        }
      );

    const userExpenses$ = this.homePageService.expenses$
      .pipe(
        filter(event => event && event.budgetId === this.currentBudget?.id),
        filter(event => event.forceReload || this.contextChanged),
        map(event => event.data.filter(exp => exp.owner === this.userManager.getCurrentUser()?.id)),
        tap(expenses => {
          this.rawExpenses = expenses;
          this.contextChanged = false;
        }),
        takeUntil(this.destroy$)
      );

    this.homePageService.contextData$
      .pipe(
        filter(data => data != null),
        tap((data) => {
          if (this.state !== WidgetState.HIDDEN) {
            this.setState(WidgetState.LOADING);
          }
          this.contextChanged = true;
          this.currentBudget = data.budget;
          this.timeframesList = data.timeframes;
          this.company = data.company;
        }),
        switchMap(() => userExpenses$),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => { this.prepareData(); },
        (err) => this.handleError(err)
      );
  }

  private refreshTableData(emitEvent = false) {
    this.overdueExpenses = this.getOverdueExpenses(this.rawExpenses);
    this.tableData = this.prepareTableData(this.overdueExpenses, this.timeframesList);
    if (this.overdueExpenses.length === 0) {
      this.activeCounter = 0;
      if (emitEvent) {
        this.homePageEventService.emitEvent({
          type: HomePageEventType.NO_OVERDUE_EXPENSES_LEFT
        });
      }
    }
    this.cdRef.detectChanges();
  }

  private getTargetExpense(expenseId: number): ExpenseDO {
    return this.rawExpenses.find(exp => exp.id === expenseId);
  }

  /* HANDLE CHANGES */
  private updateAmount(event: OverdueExpensesTableChange) {
    const { items, newValue } = event;
    const sources$ = [];

    items.forEach(item => {
      const amountKey = item.mode === ExpenseAllocationMode.Committed ? 'source_actual_amount' : 'source_amount';
      const targetExp = this.getTargetExpense(item.expenseId);
      sources$.push(
        this.expensesService.updateExpense(targetExp.id, { [amountKey]: newValue }).pipe(
          tap((updatedExpense: ExpenseDO) => targetExp[amountKey] = updatedExpense[amountKey])
        )
      );
    });

    forkJoin(sources$)
      .pipe()
      .subscribe(
        () => {
          this.utilityService.showCustomToastr(this.messageGetters.amountChanged(), null, { timeOut: this.SNACKBAR_DURATION })
        },
        (err) => this.handleError(err)
      );
  }

  private closeItems(event: OverdueExpensesTableChange) {
    const { items, viewMode, action } = event;
    const sources$ = [];

    items.forEach(item => {
      const targetExp = this.getTargetExpense(item.expenseId);
      const payload = OverdueExpensesWidgetComponent.getCloseAllocationPayload(action, item);

      sources$.push(
        this.expensesService.updateExpense(item.expenseId, payload)
          .pipe(
            tap((updatedExpense: ExpenseDO) => {
              targetExp.mode = updatedExpense.mode;
              targetExp.source_actual_amount = updatedExpense.source_actual_amount;
            })
          )
      );
    });

    forkJoin(sources$)
      .pipe(tap(() => this.refreshTableData(true)))
      .subscribe(
        () => {
          const snackMessage = items.length > 1 ? this.messageGetters.itemsClosed(viewMode) : this.messageGetters.itemClosed(viewMode);
          this.utilityService.showCustomToastr(snackMessage, null, { timeOut: this.SNACKBAR_DURATION })
        },
        (err) => this.handleError(err)
      )
  }

  private moveItems(event: OverdueExpensesTableChange) {
    const { items, viewMode } = event;
    const newTimeframe = event.newValue as BudgetTimeframe;
    const sources$ = [];

    items.forEach(item => {
      const targetExp = this.getTargetExpense(item.expenseId);
      sources$.push(
        this.expensesService.updateExpense(targetExp.id, { company_budget_alloc: newTimeframe.id })
          .pipe(
            tap((updatedExpense: ExpenseDO) => {
              targetExp.company_budget_alloc = updatedExpense.company_budget_alloc
            })
          )
      );
    });

    forkJoin(sources$)
      .pipe(tap(() => this.refreshTableData(true)))
      .subscribe(
        () => {
          const snackMessage = items.length > 1 ?
            this.messageGetters.itemsMoved(newTimeframe.name, viewMode) :
            this.messageGetters.itemMoved(newTimeframe.name, viewMode);
          this.utilityService.showCustomToastr(snackMessage, null, { timeOut: this.SNACKBAR_DURATION })
        },
        (err) => this.handleError(err)
      );
  }

  public handleDataChange($event: OverdueExpensesTableChange) {
    const actionHandler = this.actionHandlers[$event.action];
    if (actionHandler) {
      actionHandler($event);
    }
  }

  public handleCounterUpdate(counter: number) {
    this.activeCounter = counter;
    this.cdRef.detectChanges();
  }
}
