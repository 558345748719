export enum WidgetState {
  INITIAL = 'initial',
  LOADING = 'loading',
  READY = 'ready',
  EMPTY = 'empty',
  FAILED = 'failed',
  HIDDEN = 'hidden'
}

export interface WidgetConfig {
  type: WidgetType;
  grid: {
    width: number;
    minWidth?: number;
    height: number;
    minHeight?: number;
    offsetX?: number;
    offsetY?: number;
  };
  gridMd?: {
    offsetX?: number;
    offsetY?: number;
  };
  hideIfEmpty?: boolean;
  hideIfFailed?: boolean;
}

export enum WidgetType {
  OPEN_TASKS = 'openTasks',
  //OVERDUE_EXPENSES = 'overdueExpenses',
  //OPEN_EXPENSES = 'openExpenses',
  //HISTORY = 'history',
  UPDATE_METRICS = 'updateMetrics',
  CAMPAIGNS = 'campaigns',
  METRICS_PROGRESS = 'metricsProgress',
  BUDGET_SPENT = 'budgetSpent',
  CAMPAIGN_SPENT = 'campaignSpent',
  SPEND_PROGRESS = 'spendProgress',
  BUDGET_MINUS_EXPENSES = 'budgetMinusExpenses',
  ESTIMATED_BUSINESS_VALUE = 'estimatedBusinessValue',
  ASSIGN_SEGMENTS_GOOGLE_CAMPAIGNS = 'assignSegmentsGoogleCampaigns'
}
