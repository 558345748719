import { createDateString, parseDateString } from 'app/budget-object-details/components/containers/campaign-details/date-operations';
import { Component, ViewChild, inject } from '@angular/core';
import {
  CampaignDetailsState,
  ObjectDetailsCommonState
} from 'app/budget-object-details/types/budget-object-details-state.interface';
import { BudgetObjectCreationContext } from 'app/budget-object-details/types/details-creation-context.interface';
import { CampaignDetailsForm, CampaignDetailsService } from 'app/budget-object-details/services/campaign-details.service';
import { BudgetObjectActionsBuilder } from 'app/budget-object-details/services/budget-object-actions-builder.service';
import { BudgetObjectService } from '@shared/services/budget-object.service';
import { getParentFromLocation } from '@shared/utils/location.utils';
import { debounceTime, filter, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  AllocationCheckResult,
  AllocationCheckResultData,
  BudgetObjectAllocationService
} from 'app/budget-object-details/services/budget-object-allocation.service';
import { BudgetObjectType } from '@shared/types/budget-object-type.interface';
import { messages, objectPlaceholderName } from 'app/budget-object-details/messages';
import { BudgetObjectActionsShared } from 'app/budget-object-details/services/budget-object-actions-shared';
import { ProgramDO } from '@shared/types/program.interface';
import { CampaignDO, LightCampaign } from '@shared/types/campaign.interface';
import { FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { DataValidationService } from '../../../services/data-validation.service';
import { combineLatest, merge, Observable, of, Subscription } from 'rxjs';
import { getObjectTypeKey } from '@shared/utils/budget.utils';
import { HistoryObjectLogTypeNames } from '@shared/types/history-object-log-type.type';
import { BudgetObjectTagsService } from 'app/budget-object-details/services/budget-object-tags.service';
import { BudgetObjectAttachmentsService } from 'app/budget-object-details/services/budget-object-attachments.service';
import { HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { SegmentDataInheritanceAction } from '@shared/types/segment-data-inheritance.interface';
import { BudgetAllocationActionsService } from 'app/budget-allocation/services/budget-allocation-actions.service';
import { BudgetObjectMetricsService } from 'app/budget-object-details/services/budget-object-metrics.service';
import { Budget } from '@shared/types/budget.interface';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { LAST_CREATED_OBJECT_ID } from '@shared/constants/storage.constants';
import { BudgetObjectTasksService } from 'app/budget-object-details/services/budget-object-tasks.service';
import { MetricIntegrationName } from 'app/metric-integrations/types/metric-integration';
import { Integration } from 'app/metric-integrations/types/metrics-provider-data-service.types';
import { SalesforceMappingDialogComponent } from 'app/metric-integrations/salesforce/salesforce-mapping-dialog/salesforce-mapping-dialog.component';
import { SalesforceCampaignMapping } from 'app/metric-integrations/salesforce/salesforce-campaign-mapping.interface';
import { HubspotCampaignMapping } from 'app/metric-integrations/hubspot/hubspot-campaign-mapping.interface';
import { MetricMappingDetailsService } from 'app/budget-object-details/services/metric-mapping-details.service';
import { TaskListChangeEvent } from 'app/budget-object-details/components/tasks-list/tasks-list.component';
import { DrawerFormFields } from 'app/budget-object-details/components/containers/details-drawer-form';
import { ObjectHierarchy } from 'app/budget-object-details/components/object-hierarchy-nav/object-hierarchy-nav.type';
import { DetailsDrawerBaseComponent } from 'app/budget-object-details/components/containers/details-drawer-base';
import { CEGStatus } from '@shared/enums/ceg-status.enum';
import { AppRoutingService } from '@shared/services/app-routing.service';
import { ObjectMode } from '@shared/enums/object-mode.enum';
import { ObjectDetailsTabsDataService } from 'app/budget-object-details/services/object-details-tab-data.service';
import {
  BudgetObject,
  BudgetObjectEvent,
  BudgetObjectEventContext,
  BudgetObjectEventType
} from '../../../types/budget-object-event.interface';
import { Metric } from '../../details-metrics/details-metrics.type';
import { DrawerTabItem } from 'app/budget-object-details/types/object-details-tab-control-type.interface';
import { DrawerType } from 'app/budget-object-details/services/drawer-stack.service';
import { BudgetObjectCloneResponse } from '@shared/types/budget-object-clone-response.interface';
import { Configuration } from 'app/app.constants';
import { getParentObject } from 'app/budget-object-details/utils/object-details.utils';
import { CampaignDetailsFormComponent } from 'app/budget-object-details/components/containers/campaign-details-form/campaign-details-form.component';
import { MetricUpdateService } from 'app/budget-object-details/services/metric-update.service';
import { MetricUpdateAction } from 'app/budget-object-details/types/metric-update-action.enum';
import { BudgetDataStateMutationService } from '../../../services/budget-data-state-mutation.service';
import { HubspotMappingDialogComponent } from 'app/metric-integrations/hubspot/hubspot-mapping-dialog/hubspot-mapping-dialog.component';
import { BudgetService } from '@shared/services/backend/budget.service';
import { ExternalIntegrationExpenseSource } from 'app/shared/constants/external-integration-object-types';
import { CustomFieldsService } from '../../custom-fields/custom-field.service';

@Component({
  selector: 'campaign-details-drawer',
  templateUrl: './campaign-details-drawer.component.html',
  styleUrls: ['./campaign-details-drawer.component.scss'],
  providers: [
    BudgetObjectActionsShared,
    BudgetObjectMetricsService,
    BudgetObjectTagsService,
    BudgetObjectAttachmentsService,
    BudgetAllocationActionsService,
    ObjectDetailsTabsDataService
  ]
})
export class CampaignDetailsDrawerComponent extends DetailsDrawerBaseComponent<CampaignDetailsState> {
  private readonly budgetObjectActionsShared = inject(BudgetObjectActionsShared);
  protected objectDetailsService = inject(CampaignDetailsService);
  private readonly menuActionsBuilder = inject(BudgetObjectActionsBuilder);
  private readonly budgetObjectAllocationService = inject(BudgetObjectAllocationService);
  private readonly dataValidation = inject(DataValidationService);
  protected readonly gesturesManager = inject(BudgetAllocationActionsService);
  protected readonly tasksManager = inject(BudgetObjectTasksService);
  protected readonly metricMappingDetailsService = inject(MetricMappingDetailsService);
  protected readonly configuration = inject(Configuration);
  private readonly budgetDataStateMutator = inject(BudgetDataStateMutationService);
  protected readonly metricUpdateService = inject(MetricUpdateService);
  private readonly budgetService = inject(BudgetService);
  private readonly customFieldsService = inject(CustomFieldsService);
  protected campaignNameMaxLength: number;
  protected isSegmentlessCampaign = false;
  protected campaignTypes: BudgetObjectType[] = [];

  protected regularCampaigns: LightCampaign[] = [];
  protected ownChildCampaigns: LightCampaign[] = [];
  protected minEndDate: Date = null;
  protected isCustomTypeEntering = false;
  protected MetricIntegrationName = MetricIntegrationName;
  protected connectedIntegrations: Record<string, Integration[]> = {};
  protected allowedChildrenList: DrawerType[] = [];

  protected formConfig = {
    [DrawerFormFields.name]: ['', {
      validators: [
        Validators.required
      ],
      updateOn: 'blur'
    }],
    [DrawerFormFields.segment]: [null, this.dataValidation.campaignSegmentValidator(this.externalIntegrationStatusGetter.bind(this))],
    [DrawerFormFields.ownerId]: [null, Validators.required],
    [DrawerFormFields.startDate]: null,
    [DrawerFormFields.endDate]: null,
    [DrawerFormFields.location]: null,
    [DrawerFormFields.typeId]: null,
    [DrawerFormFields.customType]: '',
    [DrawerFormFields.targetAudience]: '',
    [DrawerFormFields.messaging]: '',
    [DrawerFormFields.notes]: '',
    [DrawerFormFields.vendorId]: null,
    [DrawerFormFields.vendorName]: '',
    [DrawerFormFields.currencyCode]: null,
    [DrawerFormFields.glCode]: '',
    [DrawerFormFields.amountStatus]: CEGStatus.PLANNED,
  };

  protected hierarchy: ObjectHierarchy = {
    Goal: null,
    Program: null,
    ChildCampaign: null,
    Campaign: null,
    Expense: null
  };
  protected childHierarchy: HierarchySelectItem[] = [];

  protected actualBusinessValue: number = null;
  protected targetBusinessValue: number = null;
  protected actualChildBusinessValue = 0;

  public externalIntegration = {
    isExternal: false,
    integrationName: ''
  };
  protected tabsData: DrawerTabItem[] = this.tabsDataService.createTabList();

  @ViewChild('detailsForm') detailsForm: CampaignDetailsFormComponent;

  protected metricListUpdateAction = {
    [MetricUpdateAction.DELETE]: this.handleDeleteMetric.bind(this),
    [MetricUpdateAction.UPDATE]: this.handleUpdateMetric.bind(this)
   };
  protected selectedSegmentId: number;
  protected isChildCampaignCreation: boolean;
  public isIntegratedCampaign: boolean;
  isCustomFieldsFormValid: boolean;
  customFieldsStateDiffPayload: any;
  isCustomFieldsEnabledForCG: boolean;
  resetCustomFieldsFormGroups: boolean;
  hasCustomFieldChanges: boolean;
  customFieldsFormGroup: FormGroup<any>;
  saveCampaignCalledSub: Subscription;

  constructor() {
    super();
    this.setObjectType(this.configuration.OBJECT_TYPES.campaign);
    this.saveCampaignCalledSub = this.handleDrawerSaveEvent.handleSaveCampaignCalled$
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {
      this.submitChanges(this.handleSaveAction.bind(this));
    });
  }

  protected handleDeleteMetric(metricMappingId: number): void {
    this.currentState.metricMappings = this.currentState.metricMappings.filter(metric => metric.id !== metricMappingId);
    this.prevState.metricMappings = this.prevState.metricMappings.filter(metric => metric.id !== metricMappingId);
    if (this.currentState.keyMetricId === metricMappingId) {
      this.currentState.keyMetricId = null;
      this.prevState.keyMetricId = null;
    }
  }

  protected handleUpdateMetric(metricId: number, isKeyMetricUpdated: boolean, keyMetricId: number | null, metric: Metric): void {
    if (isKeyMetricUpdated) {
      this.currentState.keyMetricId = keyMetricId;
      this.prevState.keyMetricId = keyMetricId;
    }
    this.currentState.metricMappings = this.updateMetric(this.currentState.metricMappings, metricId, metric);
    this.prevState.metricMappings = this.updateMetric(this.currentState.metricMappings, metricId, metric);
  }

  protected onInit(): void {
    this.metricUpdateService.metricUpdated$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(data => this.handleMetricMappingUpdate(data))
      )
      .subscribe(data => {
        const metricUpdateHandler = this.metricListUpdateAction[data.updateData.action];
        const { isKeyMetricUpdated, keyMetricId, metricMappingId, objectId } = data.updateData;
        metricUpdateHandler?.(
          data.metricMapping?.id || metricMappingId,
          isKeyMetricUpdated && data.metricMapping?.id === metricMappingId,
          keyMetricId,
          data.metricMapping
        );
        this.setBusinessValue();
        if (this.currentState.objectId !== objectId) {
          this.updateChildActualBusinessValue();
        }
      });

    this.companyDataService.metricIntegrations$
      .pipe(takeUntil(this.destroy$))
      .subscribe(integrations => BudgetObjectActionsShared.setConnectedIntegrations(integrations, this.connectedIntegrations));

    this.budgetObjectDetailsManager.budgetObjectEvent$
      .pipe(takeUntil(this.destroy$))
      .subscribe(budgetObjectEvent => this.handleBudgetObjectEvent(budgetObjectEvent));

    this.customFieldsService.getCFStatus()
    .pipe(takeUntil(this.destroy$))
    .subscribe(status => {
      this.isCustomFieldsEnabledForCG =  status?.isCFEnabledForCampaign;
    })
  }

  protected checkTagsLeftover(): void {
    if (!this.detailsForm.tagsControl) {
      return;
    }
    this.tagsManager.checkInputLeftover(this.detailsForm.tagsControl, this.currentState.tagMappings);
  }

  protected initObjectCreation(): void {
    this.showLoader();
    this.creationContext = AppRoutingService.getHistoryStateProperty<BudgetObjectCreationContext>('data');
    
    this.isChildCampaignCreation = !!this.creationContext?.parent?.id
    if(this.isChildCampaignCreation) {
      this.selectedSegmentId = this.creationContext.segmentId  
    }

    this.loadBudgetAndCompanyData$().pipe(
      switchMap(([company, budget]) =>
        combineLatest([
          this.objectDetailsService.initDetails(this.creationContext, { company, budget }),
          this.loadDetailsContextData$(company)
        ])
      ),
      map(([objectDetailsState]) => {
        this.setCreatedBy(objectDetailsState as ObjectDetailsCommonState);
        this.detectAddedParentObject(objectDetailsState as ObjectDetailsCommonState);
        objectDetailsState.currencyCode = this.company.currency;
        this.objectDetailsService.preFillStateAllocations(
          objectDetailsState,
          this.budgetTimeframes,
          { suppressMode: false, CEGMode: true }
        );
        this.campaignTypes = this.budgetObjectDetailsManager.filterObjectTypes(this.campaignTypes);
        this.validateContextObjectType(this.campaignTypes, objectDetailsState as ObjectDetailsCommonState);
        return objectDetailsState;
      }),
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe({
      next: state => this.onCampaignLoaded(state, false),
      error: error => this.onError(
        error,
        messages.UNABLE_TO_CREATE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectTypeAsText),
        true
      )
    });
  }

  protected loadObjectDetails(objectId: number): void {
    this.showLoader();
    this.loadBudgetAndCompanyData$().pipe(
      switchMap(([companyId, budgetId]) =>
        combineLatest([
          this.objectDetailsService.loadDetails(companyId, budgetId, objectId, { isCEGMode: true }),
          this.loadDetailsContextData$(companyId)
        ]).pipe(
          takeUntil(merge(this.destroy$, this.reset$))
        )
      ),
      tap(([state]) => this.checkStateConsistencyAndAccess(state as ObjectDetailsCommonState)),
      map(([objectDetailsState]) => {
        this.initHierarchy(objectDetailsState);
        this.defineParent(objectDetailsState);
        this.campaignTypes = this.budgetObjectDetailsManager.filterObjectTypes(this.campaignTypes, objectDetailsState.typeId);

        this.loadAttachments(objectDetailsState as ObjectDetailsCommonState);

        objectDetailsState.campaigns.forEach(campaign => {
          const campaignType = this.campaignTypes.find(item => item.id === campaign[getObjectTypeKey(this.budget, 'campaign_type')]);
          if (campaignType) {
            campaign['campaignSource'] = campaignType.name;
          }
          return campaign;
        });
        return objectDetailsState;
      }),
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe({
      next: state => {
        this.onCampaignLoaded(state);
        this.budgetObjectDetailsManager.logObjectView(
          state.objectId,
          this.companyId,
          this.budget.id,
          this.currentCompanyUser.user,
          HistoryObjectLogTypeNames.campaign,
          this.objectDetailsService);
      },
      error: error => this.onError(
        error,
        messages.NO_OBJECT_FOUND_ERROR_MSG.replace(objectPlaceholderName, this.objectTypeAsText),
        true
      ),
    });
  }

  private loadDetailsContextData$(companyId: number): Observable<any> {
    return combineLatest(
      [
        this.companyDataService.selectedCompanyDO$.pipe(tap(company => this.company = company), takeUntil(this.destroy$)),
        this.budgetObjectDetailsManager.getCompanyUsers().pipe(tap(users => this.companyUsers = users)),
        this.budgetObjectDetailsManager.getTags().pipe(tap(tags => this.tagsManager.setTags(tags))),
        this.companyDataService.budgetObjectTypes$.pipe(
          take(1),
          takeUntil(this.destroy$),
          tap(types => this.campaignTypes = [...types, this.budgetObjectDetailsManager.enterCustomTypeOption])
        ),
        this.companyDataService.productsAndMetrics$.pipe(
          takeUntil(this.destroy$),
          tap(([products, mTypes]) => {
            this.metricsManager.setProducts(products);
            this.metricsManager.setTypes(mTypes);
          })
        ),
        this.budgetObjectDetailsManager.getBudgets(this.budget?.id).pipe(tap(budgets => this.budgets = budgets)),
        this.budgetObjectDetailsManager.getTimeframes().pipe(tap(tfs => this.budgetTimeframes = tfs)),
        this.getSegmentRelatedData$,
        this.budgetObjectDetailsManager.getGoals().pipe(tap(goals => this.goals = goals)),
        this.budgetObjectDetailsManager.getLightCampaigns().pipe(tap(campaigns => this.campaigns = campaigns)),
        this.budgetObjectDetailsManager.getLightPrograms().pipe(tap(programs => this.programs = programs)),
        this.budgetObjectDetailsManager.getCompanyCurrencies(companyId).pipe(tap(currencyList => this.currencyList = currencyList)),
        this.getVendors$.pipe(takeUntil(this.destroy$)),
        this.getGLCodes$.pipe(takeUntil(this.destroy$)),
        this.currentCompanyUser$.pipe(takeUntil(this.destroy$))
      ]
    );
  }

  private onCampaignLoaded(state: CampaignDetailsState, syncState: boolean = true) {
    this.actualChildBusinessValue = 0;
    this.currentState = state as ObjectDetailsCommonState;
    this.isIntegratedCampaign = state.campaign_integrated_source;
    if (syncState) {
      this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(state) as ObjectDetailsCommonState;
    }
    this.updateChildActualBusinessValue();
    this.resetFormData();
    this.setCampaignLists();
    this.updateOwnerOptions(this.currentState.segment.segmentId, this.currentState.segment.sharedCostRuleId);
    this.defineAllowedSegments(this.currentState.ownerId);
    this.updateReadOnlyModeState();
    this.defineCompanyCurrency();
    this.loadCurrencyExchangeRates(this.currentState.currencyCode);
    this.setFormData();
    this.setBusinessValue();
    this.updateLocationOptions();
    this.onAllocationsUpdated();
    this.updateDetailsTabData();
    this.hideLoader();
    this.isSegmentlessCampaign = !this.fdSegment;
    this.setAllowedAddChildrenButtonsList();
    this.inheritParentAmountStatus(this.currentState.parentCampaignId);
    this.updateSegmentSelectConfig();

    if(this.selectedSegmentId) {
      this.isSegmentlessCampaign = false;  
    }    

    if (this.isSegmentlessCampaign) {
      this.fdCurrencyControl.disable();
    }
    this.formData.valueChanges
      .pipe(
        debounceTime(300),
        takeUntil(merge(this.destroy$, this.reset$))
      ).subscribe(() => this.syncUnsavedChangesFlag());

    this.fdLocationControl.valueChanges
      .pipe(
        takeUntil(merge(this.destroy$, this.reset$))
      ).subscribe(() => this.updateSegmentSelectConfig());
  }

  private setFormData(): void {
    const {
      segment, ownerId, parentObject, typeId, targetAudience = '', messaging = '',
      notes = '', name, startDate, endDate, vendor, vendorName = '', currencyCode,
      glCode, amountStatus = CEGStatus.PLANNED
    } = this.currentState;
    const location = this.locationService.defineLocationValue(parentObject);
    const segmentedValue = this.budgetObjectDetailsManager.segmentedValueToSelectItem(segment, this.segmentSelectItems);
    const owner = ownerId || this.currentCompanyUser?.user;
    const startDateObj = parseDateString(startDate);
    const endDateObj = parseDateString(endDate);
    const formData: CampaignDetailsForm = {
      name,
      segment: segmentedValue,
      ownerId: owner,
      typeId: typeId || null,
      customType: '',
      targetAudience,
      messaging,
      notes,
      startDate: startDateObj,
      endDate: endDateObj,
      location,
      vendorId: vendor,
      vendorName,
      currencyCode,
      amountStatus,
      glCode,
    };

    // We only set the default type when creating CG from Goal Drawer else inherit from parent Drawer
    if(!typeId && parentObject?.type === this.configuration.OBJECT_TYPES.goal) {
      formData[DrawerFormFields.typeId] = BudgetObjectActionsShared.getDefaultTypeId(this.campaignTypes)
    }

    this.formData.setValue(formData);
    this.setFormValidators(name, startDateObj);
    this.fdSegmentControl.updateValueAndValidity();
    this.performAutofill();
    this.setExternalIntegrationValidators();
  }

  private setExternalIntegrationValidators(){
    const integrationType = this.currentState.source;
    const nameControl = this.formData.controls['name'];
    const { maxObjectNameLength, maxObjectNameLengthForExternalIntegration } = this.budgetObjectDetailsManager;

    this.campaignNameMaxLength = Object.values(ExternalIntegrationExpenseSource).includes(integrationType) ? maxObjectNameLengthForExternalIntegration : maxObjectNameLength;
    nameControl.addValidators([
      Validators.maxLength(this.campaignNameMaxLength)
    ]);
    this.applyExternalIntegrationRestrictions();
  }

  private setAllowedAddChildrenButtonsList(): void {
    const items = [
      !this.currentState.parentCampaignId && !this.isIntegratedCampaign ? DrawerType.Campaign : null,
      !this.isIntegratedCampaign?DrawerType.Program:null,
      !this.isSegmentlessCampaign ? DrawerType.Expense : null,
    ];
    this.allowedChildrenList = items.filter(item => !!item);
  }

  private applyExternalIntegrationRestrictions(): void {
    const fieldsToDisable = [DrawerFormFields.name, DrawerFormFields.typeId, DrawerFormFields.currencyCode, DrawerFormFields.startDate];
    const { filteredObjectTypes, integrationTypeSelected } = this.applyExternalIntegrationToForm(
      fieldsToDisable,
      this.campaignTypes,
      this.currentState.typeId
    );
    this.hasExternalIntegrationType = integrationTypeSelected;
    this.campaignTypes = filteredObjectTypes;

    if (this.hasExternalIntegrationType) {
      this.externalIntegration = {
        isExternal: integrationTypeSelected,
        integrationName: filteredObjectTypes.find(objectType => objectType.id === this.currentState.typeId)?.name
      };
    }
  }

  private updateSegmentSelectConfig(): void {
    const hasParentCampaign = this.fdLocation?.split('_')[0] === this.configuration.OBJECT_TYPES.campaign;
    const isSegmentFieldRequired = this.hasExternalIntegrationType || hasParentCampaign;
    this.selectSegmentsConfig = BudgetObjectActionsShared.getSegmentSelectConfig(this.selectSegmentsConfig, isSegmentFieldRequired);
    if (!isSegmentFieldRequired) {
      this.fdSegmentControl.updateValueAndValidity();
    }
    
    this.isChildCampaignCreation = hasParentCampaign;
    if(hasParentCampaign) {
      this.fdSegmentControl.addValidators([Validators.required]);
    }else {
      this.fdSegmentControl.removeValidators(Validators.required);
    }
    this.fdSegmentControl.updateValueAndValidity();
  }

  public externalIntegrationStatusGetter(): boolean {
    return this.hasExternalIntegrationType;
  }

  private setCampaignLists() {
    this.regularCampaigns = this.campaigns.filter(campaign => campaign.id !== this.objectId && !campaign.parentCampaign);
    this.ownChildCampaigns = this.campaigns.filter(campaign => this.objectId && campaign.parentCampaign === this.objectId);
  }

  protected formDataToState(): Partial<CampaignDetailsState> {
    const formData = this.formData.getRawValue() as CampaignDetailsForm;

    const state: Partial<CampaignDetailsState> = {
      name: formData.name,
      targetAudience: formData.targetAudience,
      messaging: formData.messaging,
      notes: formData.notes,
      typeId: formData.typeId,
      ownerId: formData.ownerId,
      glCode: formData[DrawerFormFields.glCode] as number,
      vendor: formData[DrawerFormFields.vendorId],
      vendorName: formData[DrawerFormFields.vendorName],
      amountStatus: formData[DrawerFormFields.amountStatus],
      currencyCode: formData[DrawerFormFields.currencyCode],
      startDate: createDateString(formData.startDate),
      endDate: createDateString(formData.endDate),
      segment: this.budgetObjectDetailsManager.hierarchyItemToState(formData.segment),
    };

    const { OBJECT_TYPES } = this.configuration;
    const parentObject = getParentFromLocation(formData[DrawerFormFields.location]);
    state.parentObject = parentObject;
    state.goalId = parentObject?.type === OBJECT_TYPES.goal ? parentObject.id : null;
    state.parentCampaignId = parentObject?.type === OBJECT_TYPES.campaign ? parentObject.id : null;

    return state;
  }

  protected get isObjectOpen(): boolean {
    const mode = this.currentState?.mode;
    return mode === ObjectMode.Open || mode === ObjectMode.Planned;
  }

  protected updateMenuActions(): void {
    if (!this.currentState?.objectId) {
      this.menuActions = [];
      return;
    }

    this.menuActionsBuilder
      .reset()
      .addShowParentAction(this.objectLabel)
      .addShowChildAction(this.objectLabel, !this.childHierarchy.length)
      .addCloneAction(this.objectLabel, this.handleClone.bind(this), this.isReadOnlyMode);

    if (this.isObjectOpen) {
      this.menuActionsBuilder.addCloseAction(
        this.objectLabel,
        this.handleClose.bind(this, messages.CLOSE_CAMPAIGN_TITLE),
        !this.editPermission
      );
    } else {
      this.menuActionsBuilder.addOpenAction(this.objectLabel, this.handleOpen.bind(this), !this.editPermission);
    }
    const integrationExpenseTypeIds = BudgetObjectService.getExternalIntegrationTypeIds(this.campaignTypes);
    const isBudgetActionDisabled = integrationExpenseTypeIds.includes(this.currentState.typeId) || this.isReadOnlyMode;

    this.menuActionsBuilder
      .addChangeBudgetAction(isBudgetActionDisabled)
      .addDeleteAction(this.objectLabel, this.handleDelete.bind(this), this.isReadOnlyMode);

    this.menuActions = this.menuActionsBuilder.getActions();
  }

  protected handleClone(): void {
    const checkAndClone$ =
      this.objectDetailsService.getCampaign(this.objectId, this.campaigns).pipe(
        switchMap(campaign =>
          this.budgetObjectAllocationService.checkAllocationAmountAndCloneSegmentedObject(
            campaign,
            this.objectType,
            campaign.parentCampaign,
            this.createCloneObjectRequest$(),
            this.campaigns,
            this.programs,
            false
          )
        ),
        switchMap(
          (cloneRes: BudgetObjectCloneResponse) =>
            this.objectDetailsService.getCampaignDO(cloneRes.id).pipe(
              map(clonedCampaignDO => {
                this.campaigns.push(this.budgetDataService.convertCampaignDO(clonedCampaignDO));
                const clonedCampaignState =
                  this.objectDetailsService.createCampaignDetailsState(clonedCampaignDO, [], [], [], [], [], []);
                clonedCampaignState.parentObject =
                  getParentObject(this.configuration, clonedCampaignState.goalId, clonedCampaignState.parentCampaignId);
                this.triggerBudgetObjectEvent(clonedCampaignState.objectId, BudgetObjectEventType.Created, clonedCampaignState);
                return cloneRes;
              }),
              takeUntil(this.destroy$)
            )
        )
      );

    this.onObjectClone(campaignId => this.appRoutingService.replaceActiveDrawer(DrawerType.Campaign, campaignId), checkAndClone$);
  }

  private handleDelete(): void {
    this.budgetObjectActionsShared.handleDeleteCampaign(
      this.currentState,
      this.objectType,
      this.companyId,
      this.isDeleteActionObject,
      (message: string, close: boolean) => {
        this.onSuccess(message, close);
        this.triggerBudgetObjectEvent(this.currentState.objectId, BudgetObjectEventType.Deleted, this.currentState);
        },
      this.onError
    );
  }

  protected onObjectModeUpdated(item: ProgramDO | CampaignDO): void {
    this.prevState.mode = this.currentState.mode = item.mode;
    this.updateReadOnlyModeState();
    this.budgetObjectDetailsManager.reportDrawerDetailsChange(this.objectId, this.objectType);
    this.triggerBudgetObjectEvent(this.currentState.objectId, BudgetObjectEventType.Updated, this.currentState, this.prevState);
  }

  protected initHierarchy(objectDetailsState: CampaignDetailsState): void {
    this.hierarchy = this.budgetObjectActionsShared.buildObjectHierarchy(
      objectDetailsState,
      this.goals,
      this.campaigns,
      this.programs,
      this.segmentGroups,
      this.segments
    );
    this.childHierarchy = this.budgetObjectActionsShared.buildChildHierarchy(objectDetailsState, this.campaigns, this.programs);
  }

  protected defineParent(objectDetailsState: CampaignDetailsState) {
    const hierarchyObjectType = this.budgetObjectActionsShared.getHierarchyObjectType(objectDetailsState);
    objectDetailsState.parentObject = this.hierarchyService.getParentFromHierarchy(this.hierarchy, hierarchyObjectType);
  }

  protected setKeyMetric(metric: Metric): void {
    const newKeyMetricId = metric.id;
    const currentKeyMetricId = this.currentState.keyMetricId;
    const savedKeyMetricId = this.prevState.keyMetricId;

    if (currentKeyMetricId === newKeyMetricId) {
      this.setKeyMetricState(null);
      this.utilityService.showToast({ action: null, Message: messages.NO_KEY_METRIC_SELECTED });
      return;
    }

    if (savedKeyMetricId !== newKeyMetricId) {
      this.utilityService.showToast({ action: null, Message: messages.METRIC_BECOME_KEY_AFTER_SAVE });
    }
    this.setKeyMetricState(newKeyMetricId);
  }

  protected setKeyMetricState(id: number): void {
    this.currentState.keyMetricId = id;
    this.setBusinessValue();
    this.syncUnsavedChangesFlag();
  }

  private setBusinessValue(): void {
    const businessValueForecast =
      this.budgetObjectActionsShared.getBusinessValueForecast(
        this.currentState.keyMetricId,
        this.currentState.metricMappings,
        this.metricsManager.types,
        this.metricsManager.products
      );
    this.actualBusinessValue = businessValueForecast.actualBusinessValue;
    this.targetBusinessValue = businessValueForecast.targetBusinessValue;
    if (!businessValueForecast.hasKeyMetric) {
      this.actualBusinessValue = this.actualChildBusinessValue;
    }
  }

  protected updateTotalBusinessValue(childValue: number): void {
    this.actualChildBusinessValue = childValue;
    this.setBusinessValue();
  }

  protected updateLocationOptions() {
    const currentLocation = this.fdLocation;
    const allowedParentCampaigns = this.ownChildCampaigns.length ? [] : this.regularCampaigns;

    this.setLocationOptions(
      {
        goals: this.goals,
        campaigns: allowedParentCampaigns,
        programs: [],
        currentLocation,
        segments: this.segments,
        rules: this.sharedCostRules,
        isPowerUser: this.isPowerUser
      }, currentLocation, false, false
    );
  }

  protected handleSegmentChanged(selectedItem: HierarchySelectItem): void {
    const { segmentId, sharedCostRuleId } = this.budgetObjectDetailsManager.hierarchyItemToState(selectedItem);
    const prevSegment = this.budgetObjectDetailsManager.segmentedValueToSelectItem(this.currentState.segment, this.segmentSelectItems);
    const hasChildren = BudgetObjectActionsShared.objectHasChildren(this.currentState);

    const confirmSegmentChange$ = !hasChildren || (!segmentId && !sharedCostRuleId) ?
      of(null) :
      this.segmentDataInheritanceService
        .confirmSegmentChange(this.objectType, hasChildren, true)
        .pipe(takeUntil(this.destroy$));

    confirmSegmentChange$.subscribe(
      action => this.onChangeSegment(segmentId, sharedCostRuleId, action, prevSegment)
    );
  }

  protected onChangeSegment(
    segmentId: number,
    sharedCostRuleId: number,
    action: SegmentDataInheritanceAction,
    prevSegment: HierarchySelectItem
  ): void {
    if (action === SegmentDataInheritanceAction.None) {
      this.formData.patchValue({ [DrawerFormFields.segment]: prevSegment });
      this.isSegmentlessCampaign = !prevSegment && !this.currentState.parentCampaignId;
      return;
    }

    this.updateOwnerOptions(segmentId, sharedCostRuleId);
    this.isSegmentlessCampaign = !segmentId && !sharedCostRuleId && !this.currentState.parentCampaignId;
    this.setAllowedAddChildrenButtonsList();

    if (this.isSegmentlessCampaign) {
      if (this.currentState.currencyCode !== this.companyCurrency.code) {
        this.currentState.currencyCode = this.companyCurrency.code;
        this.fdCurrencyControl.setValue(this.currentState.currencyCode)
      }
      this.fdCurrencyControl.disable();
      this.sumAllocationsFromChildren();
      this.onAllocationsUpdated();
    } else if (this.currentState.objectId) {
      this.fdCurrencyControl.enable();
    }

    BudgetObjectActionsShared.processSegmentChangeAction(this.currentState, action, segmentId, sharedCostRuleId, true);
  }

  protected onCurrencyExchangeRatesUpdated(): void {
    this.refreshCampaignAllocations();
  }

  private sumAllocationsFromChildren() {
    const childrenTotalAllocations = BudgetObjectActionsShared.sumChildrenAllocations([
      ...this.currentState.campaigns.map(camp => camp.campaign_allocations),
      ...this.currentState.programs.map(program => program.program_allocations),
    ]);
    this.currentState.allocations.forEach(alloc => {
      const newAmount = childrenTotalAllocations[alloc.company_budget_alloc] || 0;
      const diff = newAmount - alloc.source_amount;
      alloc.amount = this.budgetObjectDetailsManager.getConvertedAmount(newAmount, this.currentState.currencyCode, alloc.company_budget_alloc);
      alloc.source_amount = newAmount;
      alloc.available = alloc.available + diff;
    });
    this.currentState.allocations = [...this.currentState.allocations];
  }

  protected handleBudgetToMoveSelected(budget: Budget) {
    this.budgetObjectActionsShared.handleBudgetToMoveSelected(
      budget,
      this.companyId,
      this.currentState.objectId,
      this.objectType,
      true,
      () => this.triggerBudgetObjectEvent(this.currentState.objectId, BudgetObjectEventType.Moved, this.currentState)
    );
  }

  public saveChanges(onSavedCb: Function = null): void {
    const isNewObject = !this.currentState.objectId;
    const initiallyWasSegmentless = CampaignDetailsService.isSegmentless(this.prevState);

    const segmentlessToRegularConfirmation$ =
      initiallyWasSegmentless && !this.isSegmentlessCampaign ?
        BudgetObjectActionsShared.openSegmentlessToRegularConfirmation(this.dialogManager) :
        of(true);

    const save$ = allocationsCheckResult => this.budgetObjectActionsShared.saveContextData$(
      this.companyId,
      this.objectType,
      this.fdTypeId,
      this.campaignTypes,
      this.currentState.tagMappings,
      this.formData
    ).pipe(
      switchMap(() => this.saveDetailsData$()),
      switchMap((data: CampaignDO) => this.budgetObjectActionsShared.saveMappings(data,
        this.prevState as ObjectDetailsCommonState,
        this.currentState as ObjectDetailsCommonState,
        this.companyId,
        this.objectType)
      ),
      switchMap((campaign: CampaignDO) => BudgetObjectActionsShared.saveTasks$(
        this.tasksManager,
        campaign,
        this.prevState as ObjectDetailsCommonState,
        this.currentState as ObjectDetailsCommonState,
        this.companyId,
        this.objectType
      )),
      switchMap((campaign: CampaignDO) => this.budgetObjectActionsShared.updateTagMappings$(
        campaign,
        this.prevState as ObjectDetailsCommonState,
        this.currentState as ObjectDetailsCommonState,
        this.companyId,
        this.objectType
      )),
      switchMap(_ =>
        this.budgetObjectAllocationService.updateObjectRelatedAllocations$(
          allocationsCheckResult,
          this.prevState,
          this.currentState,
          this.budgetTimeframes,
          false
        )
      ),
      switchMap(() => {
        if(this.isCustomFieldsEnabledForCG && this.customFieldsStateDiffPayload) {
          return of(true).pipe(tap(() => {}));
        }
        return of(true);

      }),
      takeUntil(this.destroy$)
    );

    segmentlessToRegularConfirmation$.pipe(
      switchMap(confirmed =>
        !confirmed ?
          of(false) :
          this.budgetObjectAllocationService.checkAllocationAmountForObjectDetails$(
            this.prevState,
            this.currentState,
            this.programs,
            this.campaigns,
            this.budget.suppress_timeframe_allocations,
            this.objectType,
            this.isSegmentlessCampaign
          ).pipe(
            switchMap((res: AllocationCheckResultData) => {
              if (res.result !== AllocationCheckResult.NeedOwnAllocationUpdate) {
                this.showLoader();
                return save$(res).pipe(map(_ => true));
              }
              if (res.message) {
                this.budgetObjectAllocationService.openParentAmountShortageDialog(res.message);
              }
              return of(false);
            })
          )
      ),
      filter(saved => saved),
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe({
      next: () => {
        this.onSavedSuccessfully(isNewObject);
        onSavedCb?.();
      },
      error: error => this.onError(
        error,
        messages.UNABLE_TO_SAVE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase())
      )
    });
  }

  private onSavedSuccessfully(isNewObject: boolean): void {
    if (!this.prevState) {
      this.attachmentsManager.setObjectContext({
        objectId: this.currentState.objectId,
        objectType: this.objectType,
        companyId: this.companyId
      });
      this.budgetObjectDetailsManager.logObjectView(
        this.currentState.objectId,
        this.companyId,
        this.budget.id,
        this.currentCompanyUser.user,
        HistoryObjectLogTypeNames.campaign,
        this.objectDetailsService
      );
      this.budgetObjectDetailsManager.refreshRecentlyAddedObjects(
        this.budget.id,
        HistoryObjectLogTypeNames.campaign,
        this.segments
      );
      this.updateMenuActions();
    }
    this.checkParentChange();

    this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(this.currentState);
    const message = this.budgetObjectDetailsManager.defineSuccessMessage(this.objectType, isNewObject);
    this.onSuccess(message, false);
    this.budgetObjectDetailsManager.reportDrawerDetailsChange(this.objectId, this.objectType);
    this.triggerBudgetObjectEvent(
      this.currentState.objectId,
      isNewObject ? BudgetObjectEventType.Created : BudgetObjectEventType.Updated,
      this.currentState,
      this.prevState
    );
    this.syncUnsavedChangesFlag(false);
    this.setNameValidator(this.currentState.name, this.dataValidation);
    this.fdCurrencyControl.enable();

    this.hierarchyService.setHierarchyObjectName(
      this.hierarchy,
      this.budgetObjectActionsShared.getHierarchyObjectType(this.currentState),
      this.currentState.name
    );

    if (isNewObject) {
      LocalStorageService.addToStorage(LAST_CREATED_OBJECT_ID, this.currentState.objectId);
    }

    if(this.isCustomFieldsEnabledForCG){
      this.resetCustomFieldsFormGroups = true;
      this.hasCustomFieldChanges = false;

    }
  }

  private saveDetailsData$(): Observable<CampaignDO> {
    const isNewObject = !this.currentState.objectId;
    this.saveFormData();

    return this.objectDetailsService.saveDetails(
      this.prevState,
      this.currentState,
      null,
      { isCFEnabledForCampaign: this.isCustomFieldsEnabledForCG, customFieldsStateDiff: this.customFieldsStateDiffPayload}
    ).pipe(
      tap((campaign: CampaignDO) => {
        this.currentState.updated = campaign.upd;
        this.currentState.spreadSegmentToChildren = false;
        this.currentState.externalId = campaign.external_id;
        if (isNewObject) {
          this.currentState.created = campaign.crd;
          this.campaigns.push(this.budgetDataService.convertCampaignDO(campaign));
          LocalStorageService.addToStorage(LAST_CREATED_OBJECT_ID, campaign.id);
        }
      })
    );
  }

  protected submitChanges(submitCallback): void {
    if (CampaignDetailsService.isSegmentless(this.currentState)) {
      const ownExpenses = this.currentState.expenses.filter(exp => exp.campaign === this.objectId);
      if (!!ownExpenses.length) {
        BudgetObjectActionsShared.showSaveWithoutSegmentDialog(this.dialogManager);
        return;
      }
    }
    this.submitForm(submitCallback, this.highlightNotValidFields.bind(this));
  }

  protected getContextForNewObjectCreation(): BudgetObjectCreationContext {
    return BudgetObjectActionsShared.getContextForNewObjectCreation(this.currentState);
  }

  private performAutofill(): void {
    if (!this.currentState.objectId) {
      this.budgetObjectDetailsManager.autofillSegmentValue(this.fdSegmentControl, this.segmentSelectItems);
    }
    this.budgetObjectDetailsManager.autofillTypeSelectValue(this.fdTypeIdControl, this.campaignTypes.filter(type => !!type.id));
  }

  private setFormValidators(nameValue: string, startDateValue: Date) {
    this.setNameValidator(nameValue, this.dataValidation);
    this.setDateValidator(startDateValue);
  }

  protected updateControlsValidity(): void {
    this.fdSegmentControl.updateValueAndValidity();
  }

  private setDateValidator(startDateValue: Date) {
    const startDateControl = this.fdStartDateControl;
    const endDateControl = this.fdEndDateControl;
    if (!startDateControl.value) {
      endDateControl.disable();
    }
    this.minEndDate = BudgetObjectActionsShared.getMinEndDate(startDateValue);

    startDateControl.valueChanges
      .pipe(takeUntil(merge(this.destroy$, this.reset$)))
      .subscribe(updatedStartDate => {
        this.minEndDate = BudgetObjectActionsShared.getMinEndDate(updatedStartDate);
        endDateControl.enable();
        if (endDateControl.value < updatedStartDate) {
          endDateControl.setValue(null);
        }
      });
  }

  syncCustomFieldsFormValidity(isCustomFieldsFormValid: boolean) {
    this.isCustomFieldsFormValid = isCustomFieldsFormValid;
  }

  customFieldsStateDiff(diffMap: any) {
    this.customFieldsStateDiffPayload = diffMap; 
    this.resetCustomFieldsFormGroups = false;
    this.hasCustomFieldChanges = Object.keys(diffMap).length > 0;
  }

  syncCustomFieldsFormGroup(formGroup: FormGroup) {
    this.customFieldsFormGroup = formGroup
  }
  syncCustomFieldsUsageChanges(flag: boolean = false) {
    this.hasCustomFieldChanges = flag;
  }

  public validateCustomFormFields() {
    let formGroup = this.customFieldsFormGroup;
    if(!formGroup) return;

    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } 
    });
  }

  protected handleTypeChange(): void {
    this.isCustomTypeEntering = this.fdTypeId === this.budgetObjectDetailsManager.enterCustomTypeOption.id;
    this.resetUnsavedCustomTypes();
  }

  protected handleCustomTypeChange(): void {
    this.budgetObjectActionsShared.handleCustomTypeChange(this.formData, this.campaignTypes, this.companyId, this.prevState?.typeId);
    this.isCustomTypeEntering = false;
  }

  private resetUnsavedCustomTypes(): void {
    this.campaignTypes = this.campaignTypes.filter(
      cType => !cType.isCustom || cType.id !== this.budgetObjectDetailsManager.unsavedCustomTypeId
    );
    this.formData.patchValue({ [DrawerFormFields.customType]: '' });
  }

  protected openSalesforceMappingModal = () => {
    this.budgetObjectActionsShared.openMetricIntegrationMappingDialog(
      this.company,
      this.budget,
      this.currentState as ObjectDetailsCommonState,
      MetricIntegrationName.Salesforce,
      this.connectedIntegrations[MetricIntegrationName.Salesforce][0].integrationId,
      this.isReadOnlyMode,
      SalesforceMappingDialogComponent,
      (mapping: SalesforceCampaignMapping, campaignsForSync: number[]) => this.updateSalesforceData(mapping, campaignsForSync)
    );
  }

  protected openHubspotMappingModal = () => {
    this.budgetObjectActionsShared.openMetricIntegrationMappingDialog(
      this.company,
      this.budget,
      this.currentState as ObjectDetailsCommonState,
      MetricIntegrationName.Hubspot,
      this.connectedIntegrations[MetricIntegrationName.Hubspot][0].integrationId,
      this.isReadOnlyMode,
      HubspotMappingDialogComponent,
      (mapping: HubspotCampaignMapping, campaignsForSync: number[]) => this.updateHubspotData(mapping, campaignsForSync)
    );
  }

  private updateSalesforceData(mapping: SalesforceCampaignMapping, campaignsForSync: number[]): void {
    if (!mapping) {
      return;
    }

    this.updateIntegrationData(
      (chain$: Observable<any>) =>
        this.metricMappingDetailsService.syncSalesforceObject(
          this.companyId,
          this.connectedIntegrations[MetricIntegrationName.Salesforce][0].integrationId,
          [this.currentState.objectId, ...campaignsForSync],
          chain$),
      messages.UNABLE_TO_UPDATE_SF_CAMPAIGNS_ERROR_MSG
    );
  }

  private updateHubspotData(mapping: HubspotCampaignMapping, campaignsForSync: number[]): void {
    if (!mapping) {
      return;
    }

    this.updateIntegrationData(
      (chain$: Observable<any>) =>
        this.metricMappingDetailsService.syncHubspotObject(
          this.companyId,
          this.connectedIntegrations[MetricIntegrationName.Hubspot][0].integrationId,
          [this.currentState.objectId, ...campaignsForSync],
          chain$),
      messages.UNABLE_TO_UPDATE_HS_CAMPAIGNS_ERROR_MSG
    );
  }

  private updateIntegrationData(
    sync: (chain$: Observable<any>) => Observable<any>,
    errorMessage: string
  ) {
    const updateChain$ = this.budgetObjectActionsShared.getUpdateIntegrationChain$(
      this.companyId,
      this.currentState as ObjectDetailsCommonState
    ).pipe(
      tap(() => this.setBusinessValue()),
      takeUntil(this.destroy$)
    );

    sync(updateChain$)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.hideLoader())
      )
      .subscribe({
        error: (err) => this.onError(err, errorMessage)
      });
  }

  protected handleTasksUpdate($event: TaskListChangeEvent): void {
    BudgetObjectActionsShared.handleTasksUpdate(
      $event, this.currentState as ObjectDetailsCommonState, this.prevState as ObjectDetailsCommonState
    );
    this.updateDetailsTabData();
    this.syncUnsavedChangesFlag();
  }

  protected childHierarchyClickHandler(event: Partial<HierarchySelectItem>): void {
    this.appRoutingService.routeDrawerActionByObjectType[event.objectType](event.objectId);
  }

  private triggerBudgetObjectEvent(
    campaignId: number,
    eventType: BudgetObjectEventType,
    currentState: CampaignDetailsState,
    prevState?: CampaignDetailsState
  ): void {
    const context: BudgetObjectEventContext = {
      objectId: campaignId,
      segmentId: currentState.segment?.segmentId,
      sharedCostRuleId: currentState.segment?.sharedCostRuleId,
      parentObject: currentState.parentObject,
      objectTypeId: currentState.typeId,
      objectName: currentState.name,
      objectMode: currentState.mode,
      startDate: currentState.startDate,
      endDate: currentState.endDate,
      keyMetricId: currentState.keyMetricId,
      amountStatus: currentState.amountStatus,
      externalId: currentState.externalId,
      createdDate: this.getObjectCreatedDate(currentState),
      currencyCode: currentState.currencyCode
    };

    if (prevState) {
      context.prevSegmentId = prevState.segment?.segmentId;
      context.prevSharedCostRuleId = prevState.segment?.sharedCostRuleId;
      context.prevParentObject = prevState.parentObject;
      context.prevAmountStatus = prevState.amountStatus;
    }

    const prevCampaign = this.budgetDataStateMutator.updateCampaigns(eventType, context);
    context.extras = { prevObject: prevCampaign };

    this.budgetObjectDetailsManager.triggerBudgetObjectEvent({
      targetObject: BudgetObject.Campaign,
      eventType,
      context
    });
  }

  private handleBudgetObjectEvent(budgetObjectEvent: BudgetObjectEvent): void {
    const isChildObjectTypeEvent =
      [BudgetObject.Expense, BudgetObject.Program, BudgetObject.Campaign].includes(budgetObjectEvent.targetObject);

    if (isChildObjectTypeEvent) {
      this.eventAffectedCurrentState(budgetObjectEvent)
        .pipe(
          takeUntil(this.destroy$),
          filter(affected => affected)
        )
        .subscribe(
          () => this.refreshChildObjectsDependentData(budgetObjectEvent)
        );
    }
  }

  private eventAffectedCurrentState(budgetObjectEvent: BudgetObjectEvent): Observable<boolean> {
    const parentObject = budgetObjectEvent?.context?.parentObject;
    return this.hasParentInHierarchy(
      parentObject,
      { type: this.configuration.OBJECT_TYPES.campaign, id: this.currentState.objectId }
    );
  }

  private refreshChildObjectsDependentData(budgetObjectEvent: BudgetObjectEvent): void {
    if (budgetObjectEvent.targetObject === BudgetObject.Expense) {
      this.objectDetailsService.getCampaignExpenses$(this.companyId, this.budgetId, this.currentState.objectId, true).pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        expenses => this.currentState.expenses = expenses
      );
    } else {
      const getUpdateChildObjects$: Observable<any> =
        budgetObjectEvent.targetObject === BudgetObject.Campaign ?
        this.getUpdatedCampaignsAndPrograms$(
          () => this.objectDetailsService.getChildCampaigns$(this.companyId, this.budgetId, this.currentState.objectId)
        ).pipe(
          tap(() => this.updateChildActualBusinessValue())
        ) :
        this.getUpdatedPrograms$(
          () => this.objectDetailsService.getCampaignPrograms$(this.companyId, this.budgetId, this.currentState.objectId)
        );

      getUpdateChildObjects$?.pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        () => {
          this.setCampaignLists();
          this.updateLocationOptions();
          this.initHierarchy(this.currentState);
          this.updateMenuActions();
        }
      );
    }

    this.refreshCampaignAllocations();
  }

  private refreshCampaignAllocations() {
    this.refreshStateAllocations(
      this.objectDetailsService.loadCampaignAllocationAmounts(
        this.budgetId, this.currentState.objectId, this.currentState.currencyCode, this.currentState.allocations
      )
    );
  }

  private updateChildActualBusinessValue(): void {
    const childCampaignsWithKeyMetric = (this.currentState.campaigns || []).filter(campaign => campaign.key_metric);

    const getChildActualBusinessValue$ = childCampaignsWithKeyMetric.length ?
      this.budgetService.getEstimatedBusinessValue(
        this.budgetId,
        { campaign_ids: childCampaignsWithKeyMetric.map(campaign => campaign.id).join(',')} ).pipe(
          map(response => {
            const campaignResults = response?.results?.campaigns || [];
            return campaignResults.reduce(
              (sum, campaignRes) => sum + campaignRes.target_return,
              0
            );
          })
      ) :
      of(0);

    getChildActualBusinessValue$
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.updateTotalBusinessValue(value));
  }

  protected onDestroy(): void {
    this.campaigns  = [];
    this.programs = [];
    this.campaignTypes = [];
    this.ownChildCampaigns = [];
    this.regularCampaigns = [];
    this.connectedIntegrations = {};
    this.currentState = null;
    this.prevState = null;
    this.detailsForm = null;
    this.customFieldsFormGroup = null;
    this.tabsData = [];
    this.menuActions = [];
    this.saveCampaignCalledSub?.unsubscribe();
    this.formData?.reset();
    this.formData = null;
    this.menuActionsBuilder?.reset();
  }

}
