import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TagMappingDO, TagService } from './tag.service';
import { CommonService } from './common.service';
import { getRequestOptions } from 'app/shared/utils/http-request.utils';
import { BudgetObjectCloneResponse } from '../../types/budget-object-clone-response.interface';
import { ExpenseDO, ExpenseMiniDashTotalsDO, ExpenseTotalsDO } from '../../types/expense.interface';
import { ExpenseTotalsBySegments } from '../../types/expense-totals-by-segments.type';
import { UserDO } from '../../types/user-do.interface';
import { PlanObjectExpensesData, SegmentExpensesData } from '../../types/plan-object-expenses-data.type';
import { BulkDeleteResponse, BulkOperationResponse } from '../../types/bulk-operation-response.interface';
import { BudgetTimeframe } from '../../types/timeframe.interface';
import { TagDO } from '@shared/types/tag-do.interface';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { Vendor } from '@shared/services/company-data.service';
import { GLCode } from '@shared/services/backend/gl-code.service';
import { ApiV0Response } from '@shared/types/api-v0-response.interface';
import { CustomFieldFiltersSummaryService } from 'app/header-navigation/components/filters/filter-services/custom-field-filter-summary.service';

const EXPENSES_CHUNK_SIZE = 250;

@Injectable({
  providedIn: 'root'
})
export class ExpensesService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http: HttpClient = inject(HttpClient);
  private readonly tagService = inject(TagService);
  private readonly commonService = inject(CommonService);
  private readonly customFieldFiltersSummaryService = inject(CustomFieldFiltersSummaryService);

  public apiPaths = {
    expense: 'expense',
    expenseMultiUpdate: 'expense/multi_update',
    expenseMultiDelete: 'expense/multi_delete',
    expenseType: 'expense_type',
    user: 'user',
    program: 'program',
    vendor: 'vendor',
    glCode: 'gl_code',
    tag: 'tag',
    tagMapping: 'tag_mapping',
    expenseExport: 'expense_export',
    cfExpenseExport: 'expense_export/{id}/expense_export_new',
    totalAmount: 'total_amount',
    totalsBySegments: 'totals_by_segments',
    totalsBySegmentsWithStatuses: 'totals_by_segments_with_statuses',
    totalsByCampaigns: 'totals_by_campaigns',
    totalsByPrograms: 'totals_by_programs',
    logView: 'log_view',
    moveToBudget: 'move_to_other_budget/',
    addRelated: 'add_related/',
    countsByGroup: 'counts_by_group',
    miniDash: 'mini-dash',
    ids: 'expense/ids',
    expenseCustomFieldFilters: 'expense/expense_filter',
  };

  messages = {
    CONFIRM_AUTOMATED_EXPENSE_DELETION_PLURAL: 'Some of the selected expenses were automatically created based on your integration. If you delete these expenses, they will not be recreated or updated.',
    CONFIRM_AUTOMATED_EXPENSE_DELETION_SINGULAR: 'This expense was automatically created based on your integration. If you delete this kind of expense, then it will not be recreated or updated. Would you like to proceed and delete this expense?',
    CONFIRM_AUTOMATED_EXPENSE_DELETION_TITLE_PLURAL: 'Delete automated expenses',
    CONFIRM_AUTOMATED_EXPENSE_DELETION_TITLE_SINGULAR: 'Delete automated expense',
  };

  public static getTimeframeOrder (timeframeId: number, timeframes: BudgetTimeframe[]): number {
    return timeframes.find(tf => tf.id === timeframeId)?.order;
  }

  getExpenses(data: object): Observable<ExpenseDO[]> {
    return this.http.get<ExpenseDO[]>(this.apiV2Url + this.apiPaths.expense + '/', getRequestOptions(data));
  }

  getExpenseById(id: number, generateExtraUrls = false): Observable<ExpenseDO> {
    return this.http.get<ExpenseDO>(
      this.apiV2Url + this.apiPaths.expense + '/' + id + '/',
      getRequestOptions({ generate_extra_expense_uris: generateExtraUrls })
    );
  }

  getExpensesByChunks(params: object, totalCount: number, chunkSize = EXPENSES_CHUNK_SIZE): Observable<ExpenseDO[]> {
    const requests = [];

    for (let offset = 0; offset < totalCount; offset += chunkSize) {
      const request = this.loadExpensesChunk(params, chunkSize, offset);
      requests.push(request);
    }

    return requests.length ?
      forkJoin(requests)
        .pipe(
          map((responses: Array<ExpenseDO[]>) =>
          responses.reduce((resultExpenses: ExpenseDO[], response) => [...resultExpenses, ...response], [])
          )
        ) :
      of([]);
  }

  loadExpensesChunk(params: object, limit: number, offset: number): Observable<ExpenseDO[]> {
    
    // API endpoint for Normal and Combined Filters to POST request

    let customFiledFiltersPayload = { ...params };

    if(params['custom_fields'] && !Object.keys(params['custom_fields']).length) {
      delete customFiledFiltersPayload['custom_fields']
    }

    let expenseCustomFieldFilterPayload = { 
      ...customFiledFiltersPayload,
        include_nested: String(params['include_nested']),
        include_pseudo_objects: String(params['include_pseudo_objects']), 
        limit,
        offset
    }
        
    return this.http.post<ExpenseDO[]>(
      this.apiV2Url + this.apiPaths.expenseCustomFieldFilters + '/',
      expenseCustomFieldFilterPayload
    ).pipe(
      map((response) => {
        return response;
      })
    
    );
    
  }

  getExpenseIds(data: object): Observable<number[]> {
    return this.http.get<number[]>(this.apiV2Url + this.apiPaths.ids  + '/', getRequestOptions(data));
  }

  getExpenseTypes(data: object): Observable<any[]> {
    return this.http.get<any[]>(this.apiV2Url + this.apiPaths.expenseType  + '/', getRequestOptions(data));
  }

  getVendors(data: object): Observable<Vendor[]> {
    return this.http.get<Vendor[]>(this.apiV2Url + this.apiPaths.vendor + '/', getRequestOptions(data));
  }

  addVendor(data: Partial<Vendor>): Observable<Vendor> {
    return this.http.post<Vendor>(
      this.apiV2Url + this.apiPaths.vendor + '/',
      JSON.stringify(data)
    );
  }

  getGlCodes(data: object): Observable<GLCode[]> {
    return this.http.get<GLCode[]>(this.apiV2Url + this.apiPaths.glCode + '/', getRequestOptions(data));
  }

  getUsers(data: object): Observable<UserDO[]> {
    return this.http.get<UserDO[]>(this.apiV2Url + this.apiPaths.user + '/', getRequestOptions(data));
  }

  getPrograms(data: object): Observable<any[]> {
    return this.http.get<any[]>(this.apiV2Url + this.apiPaths.program + '/', getRequestOptions(data));
  }

  getTags(data: object): Observable<TagDO[]> {
    return this.tagService.getTags(data);
  }

  createTag(data: Partial<TagDO>): Observable<TagDO> {
    return this.tagService.createTag(data);
  }

  addExpense(data: Partial<ExpenseDO>): Observable<ExpenseDO> {
    return this.http.post<ExpenseDO>(
      this.apiV2Url + this.apiPaths.expense + '/',
      JSON.stringify(data)
    );
  }

  updateExpense(expenseId: number, data: Partial<ExpenseDO>): Observable<ExpenseDO> {
    return this.http.patch<ExpenseDO>(
      this.apiV2Url + this.apiPaths.expense + `/${expenseId}/`,
      JSON.stringify(data)
    );
  }

  updateMultiExpenses(data: object): Observable<BulkOperationResponse<ExpenseDO>> {
    return this.http.patch<BulkOperationResponse<ExpenseDO>>(
      this.apiV2Url + this.apiPaths.expenseMultiUpdate + '/',
      JSON.stringify(data)
    );
  }

  deleteExpense(expenseId: number): Observable<void> {
    return this.http.delete<void>(this.apiV2Url + this.apiPaths.expense + `/${expenseId}/`);
  }

  deleteMultiExpenses(expenseIds: number[]): Observable<BulkDeleteResponse> {
    return this.http.delete<BulkDeleteResponse>(this.apiV2Url + this.apiPaths.expenseMultiDelete + `/?ids=${expenseIds}`);
  }

  getTagMappings(companyId: number, expenseId: number): Observable<TagMappingDO[]> {
    return this.tagService.getTagMappings(companyId, { map_id: expenseId });
  }

  exportBudgetExpensesCSV(data: object): Observable<any> {
    
    // Convert it to POST API with Custom Field Filters supported in expense export
    let payload = { ...data };
    
    if(payload['custom_fields'] && !Object.keys(payload['custom_fields']).length) {
      delete payload['custom_fields']
    }

    if('include_nested' in data) {
      payload['include_nested'] = String(data['include_nested']);
    }

    if('include_pseudo_objects' in data) {
      payload['include_pseudo_objects'] = String(data['include_pseudo_objects']);
    }

    return this.http.post(
      (this.apiV2Url + this.apiPaths.cfExpenseExport + '/').replace('{id}', data['budget']),
      payload,
      { responseType: 'text' }
    );
    
  }

  cloneExpenseV2(expenseId: number): Observable<BudgetObjectCloneResponse> {
    return this.http.post<BudgetObjectCloneResponse>(`${this.apiV2Url}${this.apiPaths.expense}/${expenseId}/clone/`, {});
  }

  getExpensesTotalAmount(params: object): Observable<ExpenseTotalsDO> {

    // API endpoint for Normal and Combined Filters to POST request

    let customFiledFiltersPayload = { ...params };

    if(params['custom_fields'] && !Object.keys(params['custom_fields']).length) {
      delete customFiledFiltersPayload['custom_fields']
    }

    let expensesTotalFilterPayload = { ...customFiledFiltersPayload };

    if('include_nested' in params) {
      expensesTotalFilterPayload['include_nested'] = String(params['include_nested']);
    }

    if('include_pseudo_objects' in params) {
      expensesTotalFilterPayload['include_pseudo_objects'] = String(params['include_pseudo_objects']);
    }
    
    // Add budget to the total_amount filter API Payload 
    if(!('budget' in params)) {
      expensesTotalFilterPayload['budget'] = params['budget_id'];
      delete expensesTotalFilterPayload['budget_id'];
    }
  
    return this.http.post<ExpenseTotalsDO>(
      this.apiV2Url + this.apiPaths.expense  + '/' + this.apiPaths.totalAmount + '/',
      expensesTotalFilterPayload
    );

  }

  getMiniDashExpensesTotalAmount(params: object): Observable<ExpenseMiniDashTotalsDO> {
    return this.http.get<ExpenseMiniDashTotalsDO>(
      this.apiV2Url + this.apiPaths.miniDash + '/',
      getRequestOptions(params)
    );
  }

  getOwnerList(companyId: number, budgetId: number, segments: { segment1: number[] }): Observable<ApiV0Response<any>> {
    return this.commonService.getOwnerList(companyId, budgetId, segments);
  }

  logExpenseView(expenseId: number): Observable<void> {
    return this.http.post<void>(`${this.apiV2Url}${this.apiPaths.expense}/${expenseId}/${this.apiPaths.logView}/`, null);
  }

  getTotalsBySegments(budgetId: number): Observable<ExpenseTotalsBySegments> {
    return this.http.get<ExpenseTotalsBySegments>(
        `${this.apiV2Url}${this.apiPaths.expense}/${this.apiPaths.totalsBySegments}/`,
        getRequestOptions({ budget_id: budgetId })
    );
  }

  getTotalsByCampaigns(budgetId: number): Observable<PlanObjectExpensesData> {
    return this.http.get<PlanObjectExpensesData>(
      `${this.apiV2Url}${this.apiPaths.expense}/${this.apiPaths.totalsByCampaigns}/`,
      getRequestOptions({ budget_id: budgetId })
    );
  }

  getTotalsByPrograms(budgetId: number): Observable<PlanObjectExpensesData> {
    return this.http.get<PlanObjectExpensesData>(
      `${this.apiV2Url}${this.apiPaths.expense}/${this.apiPaths.totalsByPrograms}/`,
      getRequestOptions({ budget_id: budgetId })
    );
  }

  getTotalsBySegmentsWithStatuses(budgetId: number): Observable<SegmentExpensesData> {
    return this.http.get<SegmentExpensesData>(
      `${this.apiV2Url}${this.apiPaths.expense}/${this.apiPaths.totalsBySegmentsWithStatuses}/`,
      getRequestOptions({ budget_id: budgetId })
    );
  }

  moveToBudget(expenseId: number, budgetId: number, companyId: number): Observable<any> {
    return this.http.patch(
      `${this.apiV2Url}${this.apiPaths.expense}/${expenseId}/${this.apiPaths.moveToBudget}`,
      {
        budget: budgetId,
        company: companyId
      }
    );
  }

  multiMoveToBudget(expenseIds: number[], budgetId: number): Observable<{ message: string }> {
    return this.http.patch<{ message: string }>(
      `${this.apiV2Url}${this.apiPaths.expense}/${this.apiPaths.moveToBudget}`,
      {
        budget: budgetId,
        expenses: expenseIds
      }
    );
  }

  addRelatedExpenses(expenseId: number, budgetAllocationIds: number[]): Observable<any> {
    return this.http.post(
      `${this.apiV2Url}${this.apiPaths.expense}/${expenseId}/${this.apiPaths.addRelated}`,
      { allocation_ids: budgetAllocationIds }
    );
  }

  getCountsByGroup(data: {[key: string]: string}): Observable<Record<number, number>> {
    // API endpoint to get Count By Groups for Normal and Combined Filters with POST request
    
    let params = data
    let customFiledFiltersPayload = { ...params };

    if(params['custom_fields'] && !Object.keys(params['custom_fields']).length) {
      delete customFiledFiltersPayload['custom_fields']
    }

    let expensesGroupCountFilterPayload = { ...customFiledFiltersPayload };

    if('include_nested' in params) {
      expensesGroupCountFilterPayload['include_nested'] = String(params['include_nested']);
    }

    if('include_pseudo_objects' in params) {
      expensesGroupCountFilterPayload['include_pseudo_objects'] = String(params['include_pseudo_objects']);
    }
  
    return this.http.post<Record<number, number>>(
      `${this.apiV2Url}${this.apiPaths.expense}/${this.apiPaths.countsByGroup}/`,
      expensesGroupCountFilterPayload
    );
  }
}
