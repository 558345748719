import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UtilityService } from 'app/shared/services/utility.service';
import { Subject } from 'rxjs';
import { GoalTypeService } from 'app/shared/services/backend/goal-type.service';
import { takeUntil } from 'rxjs/operators';
import { ProgramTypeDO } from 'app/shared/types/program.interface';
import { BudgetObjectDialogService } from '../../shared/services/budget-object-dialog.service';
import * as utils from 'app/shared/utils/notification-dialog.utils';
import { DialogContext } from '../../shared/types/dialog-context.interface';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmType } from '../../shared/utils/notification-dialog.utils';
import { Configuration } from 'app/app.constants';
import { CompanyDataService } from '../../shared/services/company-data.service';
import STRING_CONSTANTS from './constants';
import { BudgetSelectionModalService } from '../../shared/services/budget-selection-modal.service';
import { BudgetSelectionUsageCountService } from '../budget-selection-attributes-tags/budget-selection-attributes-tags.service';

@Component({
  selector: 'tab-goal-types',
  templateUrl: './tab-goal-types.component.html',
  styleUrls: ['./tab-goal-types.component.scss']
})
export class GoalTypesTabComponent implements OnChanges, OnDestroy {
  @Input() companyId: number;
  @Input() budgetId: number;
  private readonly destroy$ = new Subject<void>();
  goalTypeList: ProgramTypeDO[];
  appliedSorting = true;
  NoDataText = '';
  searchString = this.config.attributesAndTagsLabel.GOAL_TYPES;  
  selectedItemId: number;
  selectedItemName: string;
  
  public gridColumns = [
    { name: 'name', label: STRING_CONSTANTS.COLUMN_HEADERS.COLUMN1, sortable: true, width: '40%' },
    { name: 'is_enabled', label: STRING_CONSTANTS.COLUMN_HEADERS.COLUMN2, sortable: true, width: '25%' },
    { name: 'usage_count', label: STRING_CONSTANTS.COLUMN_HEADERS.COLUMN3, sortable: true, width: '20%' }
  ];

  enableSwitchTooltip = this.config.goalTypesDescriptions.enableTooltip;

  constructor(
    private readonly utilityService: UtilityService,
    private readonly goalTypeService: GoalTypeService,
    private readonly dialogService: BudgetObjectDialogService,
    private readonly dialog: MatDialog,
    private config: Configuration,
    private companyDataService : CompanyDataService,    
    private budgetSelectionModalService: BudgetSelectionModalService,
    private usageService: BudgetSelectionUsageCountService
  ) {
    this.NoDataText = this.config.attributesAndTagsLabel.GOAL_TYPE;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyId) {
      this.getGoalTypes(this.companyId);
    }
  }

  getGoalTypes(companyId: number) {
    this.utilityService.showLoading(true);
    this.goalTypeService.getGoalTypes({ company: companyId })
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        data => this.onGoalTypesLoaded(data),
        error => this.utilityService.handleError(error)
      );
  }

  addGoalType(data) {
    this.utilityService.showLoading(true);
    data.is_custom = true;
    data.company = this.companyId;
    this.goalTypeService.createGoalType(data)
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        () => this.onGoalTypeEvent(this.config.goalTypesDescriptions.created),
        error => this.utilityService.handleError(error)
      );
  }

  updateGoalType(data) {
    this.utilityService.showLoading(true);
    this.goalTypeService.updateGoalType(data.id, data)
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        () => this.onGoalTypeEvent(this.config.goalTypesDescriptions.updated),
        error => this.utilityService.handleError(error)
      );
  }

  updateStatusChange(data) {
    this.utilityService.showLoading(true);
    this.goalTypeService.updateGoalType(data.id, data)
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      () => this.onGoalTypeEvent(this.config.goalTypesDescriptions.updated),
      error => this.utilityService.handleError(error)
    );
  }

  deleteGoalType(row) {
    const deleteHandler = () => {
      this.utilityService.showLoading(true);
      this.goalTypeService.deleteNewWorldGoalType(row.id, {company:this.companyId})
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        () => this.onGoalTypeEvent(this.config.goalTypesDescriptions.deleted),
        () => this.showDeleteNotificationDialog(row.name)
      );
    };
    const dialogMessage = `You will permanently lose the '${row.name}' goal type from everywhere it is used. '${row.name}' will be replaced with 'Other'. `;

    this.dialogService.openDeleteEntityDialog(deleteHandler, null, { title: 'Permanently Delete Goal Type?', message: dialogMessage });
  }

  onGoalTypeEvent(msg: string) {
    this.utilityService.showToast({ Title: '', Message: msg, Type: 'success' });
    this.utilityService.showLoading(false);
    this.getGoalTypes(this.companyId);
  }

  showDeleteNotificationDialog(goalTypeName: string) {
    const dialogData: DialogContext = utils.getConfirmationDialogData(ConfirmType.DeleteExpenseType, goalTypeName);
    this.dialog.open(ConfirmationDialogComponent, {
      width: dialogData.width,
      data: dialogData
    });
  }

  onGoalTypesLoaded(data) {
    this.goalTypeList = data;
    const updatedData = data.map(rawGoalType => ({
      id: rawGoalType.id,
      name: rawGoalType.name,
      isCustom: rawGoalType.is_custom,
      isEnabled: rawGoalType.is_enabled,
      createdDate: rawGoalType.crd && new Date(rawGoalType.crd),
      updatedDate: rawGoalType.upd && new Date(rawGoalType.upd),
      status: rawGoalType.status,
      companyId: rawGoalType.company
    }));

    this.companyDataService.expenseTypes.next(this.companyDataService.currentExpenseTypes = updatedData)
    this.utilityService.showLoading(false);
  }

  actionOnCountEvent(event) {
    this.selectedItemId = event.id;
    this.selectedItemName = event.name;
    this.usageService.getBudgetSelectionUsageCount('goal_type', event.id, { company: this.companyId })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => this.onBudgetSelectionUsageCountLoaded(data),
        error: error => this.utilityService.handleError(error),
        complete: () => console.log('Budget selection usage count loaded.')
      });
  }
  

  onBudgetSelectionUsageCountLoaded(groups) {
    const data = {
      title: 'View Budget',
      content: `This Goal Type is included in multiple budgets. <br/> Select the specific budget from the drop-down menu to view it.`,
      icon: {
        prefix: 'fas',
        name: 'sack-dollar'
      },
      buttons : [
        { text: 'View', color: 'primary', disabled: true }
      ],
      currentBudgetId : this.budgetId,
      selectedItemId : this.selectedItemId,
      selectedItemName : this.selectedItemName,
      selectedTab : 'goalTypes',
      groups : [
        ...groups
      ]
    }
    if (groups.length === 1) {
      data.content = `This Goal Type is included in <b> ${groups[0].budget_name}</b> budget.`;
      data.buttons[0].disabled = false;
    }
    this.budgetSelectionModalService.openBudgetSelectionDialog(data);
  }


}
