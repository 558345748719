import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Configuration } from 'app/app.constants';
import { WidgetConfig, WidgetState } from '../../types/widget.interface';
import { WidgetStateService } from '../../services/widget-state.service';
import { HomePageService } from '../../services/home-page.service';
import {
  BusinessValueTableData,
  BusinessValueTableRecord,
  BusinessValueTableSummaryRecord,
  BusinessValueTableTotalRecord
} from 'app/shared/components/business-value-table.component.ts/business-value-table.types';
import { UtilityService } from 'app/shared/services/utility.service';
import { CompanyDO } from 'app/shared/types/company.interface';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetService } from 'app/shared/services/backend/budget.service';
import { BudgetEstimatedBusinessValue } from 'app/shared/types/budget-estimated-business-value.interface';
import { SharedCostRule } from '@shared/types/shared-cost-rule.interface';
import { BudgetSegmentAccess } from '@shared/types/segment.interface';


@Component({
  selector: 'estimated-business-value-widget',
  styleUrls: ['./estimated-business-value-widget.component.scss'],
  templateUrl: './estimated-business-value-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EstimatedBusinessValueWidgetComponent implements OnInit, OnDestroy {
  @Input() config: WidgetConfig;

  private readonly destroy$ = new Subject<void>();
  private budget: Budget = null;
  private company: CompanyDO = null;
  private contextChanged = false;
  private segments: BudgetSegmentAccess[] = [];
  private costRules: SharedCostRule[] = [];

  public state = WidgetState.INITIAL;
  public widgetState = WidgetState;
  public tooltipText = `Campaign Value\n
    Campaign value quantifies the impact of a given campaign in financial terms. It is calculated by
    multiplying the current value of a key metric by its average revenue (or profit) per outcome.`;
  public tableData: BusinessValueTableData;

  constructor(
    private readonly widgetStateManager: WidgetStateService,
    private readonly homePageService: HomePageService,
    private readonly budgetService: BudgetService,
    private readonly configuration: Configuration,
    private readonly cdRef: ChangeDetectorRef,
    private readonly utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.setState(WidgetState.LOADING);
    this.homePageService.noBudgets$
      .pipe(
        takeUntil(this.destroy$),
        take(1)
      )
      .subscribe(
        () => { this.setState(WidgetState.EMPTY); }
      );
    this.loadContextData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private handleError(err) {
    this.utilityService.handleError(err);
  }

  private prepareTableData(data: BudgetEstimatedBusinessValue) {
    const { results } = data;
    const { campaigns, return_on_marketing_plan: summary, all_campaigns: campaignsTotal, budget_details: budgetDetails } = results;
    const filteredCampaigns = this.homePageService.filterCampaignsBusinessValueAccess(campaigns, this.segments, this.costRules);

    this.contextChanged = false;
    if (!filteredCampaigns?.length) {
      this.setState(WidgetState.EMPTY);
      return;
    }

    const summaryRecord: BusinessValueTableSummaryRecord = {
      name: 'Return on Marketing Plan',
      targetROI: summary.target_roi,
      currentROI: summary.current_roi,
    };
    const campaignRecords: BusinessValueTableRecord[] = filteredCampaigns
      .map(campaignStats => ({
        campaignId: campaignStats.campaign_id,
        name: campaignStats.campaign_name,
        targetROI: campaignStats.target_roi,
        currentROI: campaignStats.current_roi,
        targetReturn: campaignStats.target_return,
        currentReturn: campaignStats.current_return,
        lowForecast: campaignStats.low_forecast,
        highForecast: campaignStats.high_forecast,
        owner: campaignStats.campaign_owner_name
      }));
    const totalRecord: BusinessValueTableTotalRecord = {
      name: 'All Campaigns',
      // TODO: Hiding (nullifying) ROI totals temporarily as Per PUP-4198
      // targetROI: campaignsTotal.target_roi,
      // currentROI: campaignsTotal.current_roi,
      targetROI: null,
      currentROI: null,
      targetReturn: campaignsTotal.target_return,
      currentReturn: campaignsTotal.current_return,
      lowForecast: campaignsTotal.low_forecast,
      highForecast: campaignsTotal.high_forecast,
      owner: budgetDetails.budget_owner_name
    };

    this.tableData = {
      campaigns: campaignRecords,
      total: totalRecord,
      summary: summaryRecord
    };

    this.setState(WidgetState.READY);
  }

  private loadContextData() {
    const estimatedBusinessValue$ = this.homePageService.estimatedBusinessValue$
      .pipe(
        filter(event => event && event.budgetId === this.budget?.id),
        filter(event => event.forceReload || this.contextChanged),
        map(event => event.data),
      );

    this.homePageService.contextData$
      .pipe(
        filter(data => data != null),
        tap((data) => {
          if (this.state !== WidgetState.HIDDEN) {
            this.setState(WidgetState.LOADING);
          }
          this.contextChanged = true;
          this.company = data.company;
          this.budget = data.budget;
          this.segments = data.segments;
          this.costRules = data.sharedCostRules;
        }),
        switchMap(() => estimatedBusinessValue$),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (data) => this.prepareTableData(data),
        (err) => this.handleError(err)
      );
  }

  private setState(state) {
    this.state = state;
    this.widgetStateManager.setState(this.state, this.config);
    this.cdRef.detectChanges();
  }
}
