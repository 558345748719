import { CompanyServiceActionType } from '../enums/interceptor-action-type.enum';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subject, combineLatest } from 'rxjs';
import { Company, CompanyDO } from '../types/company.interface';
import { CommonService } from './backend/common.service';
import { ExpensesService } from './backend/expenses.service';
import { BudgetObjectType } from '../types/budget-object-type.interface';
import { TagService } from './backend/tag.service';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CampaignService } from './backend/campaign.service';
import { MetricDO, MetricService } from './backend/metric.service';
import { MetricType } from '../types/budget-object-metric.interface';
import { GoalsService } from './backend/goals.service';
import { ProgramService } from './backend/program.service';
import { ProgramTypeDO } from '../types/program.interface';
import { SalesforceDataService } from 'app/metric-integrations/salesforce/salesforce-data.service';
import { CampaignTypeDO } from '../types/campaign.interface';
import { CompanyService } from './backend/company.service';
import { HubspotDataService } from 'app/metric-integrations/hubspot/hubspot-data.service';
import { MetricIntegrationName } from 'app/metric-integrations/types/metric-integration';
import { MetricIntegrations } from 'app/metric-integrations/types/metric-integrations-status.interface';
import { GoogleAdsDataService } from '../../metric-integrations/google-ads/google-ads-data.service';
import { CompanyUserService } from './backend/company-user.service';
import { CompanyUserDO, CompanyUserStatus } from '../types/company-user-do.interface';
import { ExtendedUserDO, UserDO } from '../types/user-do.interface';
import { Integration } from 'app/metric-integrations/types/metrics-provider-data-service.types';
import { Budget } from '../types/budget.interface';
import { LinkedInDataService } from 'app/metric-integrations/linkedin/linkedin-data.service';
import { FacebookDataService } from 'app/metric-integrations/facebook-ads/facebook-data.service';
import { GoalTypeDO } from '../types/goal.interface';
import { TagDO } from '../types/tag-do.interface';
import { ProductDO, ProductService } from './backend/product.service';
import { ActionInterceptorsService } from '@shared/services/action-interceptors.service';
import { ObjectTypeDO, ObjectTypeService } from '@shared/services/backend/object-type.service';
import { UserManager } from 'app/user/services/user-manager.service';

// TODO: define real types for them later
export interface Vendor { id: number; name: string; [key: string]: any; }
export interface GLCode { id: number; name: string; [key: string]: any; }
export interface Tag { id: number, name: string; [key: string]: any; }
export interface TagMapping { [key: string]: any; }

const DEFAULT_INTEGRATIONS_STATUS: MetricIntegrations = {
  [MetricIntegrationName.Salesforce]: null,
  [MetricIntegrationName.Hubspot]: null,
  [MetricIntegrationName.GoogleAds]: null,
  [MetricIntegrationName.LinkedinAds]: null,
  [MetricIntegrationName.FacebookAds]: null,
};

@Injectable({
  providedIn: 'root'
})
export class CompanyDataService {
  private readonly objectTypeService = inject(ObjectTypeService);
  private readonly userManager = inject(UserManager);
  private readonly commonService = inject(CommonService);
  private readonly expensesService = inject(ExpensesService);
  private readonly campaignService = inject(CampaignService);
  private readonly programService = inject(ProgramService);
  private readonly goalService = inject(GoalsService);
  private readonly tagService = inject(TagService);
  private readonly metricService = inject(MetricService);
  private readonly salesforceService = inject(SalesforceDataService);
  private readonly hubspotService = inject(HubspotDataService);
  private readonly googleAdsDataService = inject(GoogleAdsDataService);
  private readonly linkedInDataService = inject(LinkedInDataService);
  private readonly facebookDataService = inject(FacebookDataService);
  private readonly companyService = inject(CompanyService);
  private readonly companyUserService = inject(CompanyUserService);
  private readonly productService = inject(ProductService);
  private readonly actionInterceptorsService = inject(ActionInterceptorsService);

  private readonly userIdPlaceholder = '{userId}';
  private readonly selectedCompanyLSKey = `selectedCompany_${this.userIdPlaceholder}`;
  private initCompanyId: number;

  private readonly companyList = new BehaviorSubject<Company[]>(null);
  private currentCompanyList: Company[] = [];
  private readonly selectedCompany = new BehaviorSubject<Company>(null);
  private readonly selectedCompanyDO = new BehaviorSubject<CompanyDO>(null);

  public _budgetObjectTypes$ = new BehaviorSubject<BudgetObjectType[]>(null);
  public campaignTypes = new Subject<BudgetObjectType[]>();
  public expenseTypes = new Subject<BudgetObjectType[]>();
  private readonly users = new Subject<ExtendedUserDO[]>();
  private readonly companyUsersDO = new BehaviorSubject<CompanyUserDO[]>(null);
  // Company users are users having either ACTIVE or DISABLED status in the current company
  private readonly companyUsersList = new Subject<UserDO[]>();
  public glCodes = new Subject<GLCode[]>();
  public vendors$ = new BehaviorSubject<Vendor[]>(null);
  public tags = new Subject<TagDO[]>();

  private readonly tagMappings = new Subject<TagMapping[]>();
  private readonly goalTypes = new Subject<BudgetObjectType[]>();
  public programTypes = new Subject<BudgetObjectType[]>();
  private readonly products = new BehaviorSubject<ProductDO[]>(null);
  private readonly metrics = new BehaviorSubject<MetricType[]>(null);
  private readonly metricIntegrations = new BehaviorSubject<MetricIntegrations>(DEFAULT_INTEGRATIONS_STATUS);
  private resetMetricIntegrations$ = new Subject<void>();

  public companyList$ = this.companyList.asObservable();
  public selectedCompany$ = this.selectedCompany.asObservable();
  public selectedCompanyDO$ = this.selectedCompanyDO.asObservable();
  public budgetObjectTypes$ = this._budgetObjectTypes$.asObservable().pipe(filter(d => !!d));
  public campaignTypesList$ = this.campaignTypes.asObservable();
  public expenseTypeList$ = this.expenseTypes.asObservable();
  public userList$ = this.users.asObservable();
  public companyUsersList$ = this.companyUsersList.asObservable();
  public glCodeList$ = this.glCodes.asObservable();
  public vendorList$ = this.vendors$.asObservable().pipe(filter(data => !!data));
  public tagList$ = this.tags.asObservable();
  public tagMappingList$ = this.tagMappings.asObservable();
  public companyUsersDO$ = this.companyUsersDO.asObservable();

  public goalTypes$ = this.goalTypes.asObservable();
  public programTypes$ = this.programTypes.asObservable();
  public products$ = this.products.asObservable();
  public metrics$ = this.metrics.asObservable();
  public productsAndMetrics$: Observable<[ProductDO[], MetricType[]]> = combineLatest([this.products, this.metrics]).pipe(
    filter(data => !!data[0] && !!data[1])
  );
  public metricIntegrations$ = this.metricIntegrations.asObservable();

  private currentSelectedCompany: Company;
  private currentUserList: ExtendedUserDO[];
  public currentTagList: TagDO[];
  private currentGoalTypes: BudgetObjectType[];
  public currentCampaignTypes: BudgetObjectType[];
  public currentProgramTypes: BudgetObjectType[];
  public currentExpenseTypes: BudgetObjectType[];
  private currentProducts: ProductDO[];
  private currentMetrics: MetricType[];
  public currentGlCodes: GLCode[];
  private selectedBudgetId: number;
  private currentObjectTypes: BudgetObjectType[];

  public readonly selectedCompanyStorage = {
    save: (userId: number, companyId: number) => {
      const key = this.selectedCompanyStorage.getStorageKey(userId);
      localStorage.setItem(key, companyId.toString());
    },
    get: (userId: number) => {
      const key = this.selectedCompanyStorage.getStorageKey(userId);
      const value = localStorage.getItem(key);
      return value != null ? Number.parseInt(value, 10) : null;
    },
    remove: (userId: number) => {
      if (this.selectedCompanyStorage.get(userId) != null) {
        const key = this.selectedCompanyStorage.getStorageKey(userId);
        localStorage.removeItem(key);
      }
    },
    getStorageKey: userId => this.selectedCompanyLSKey.replace(this.userIdPlaceholder, userId)
  };

  public static convertBudgetObjectTypes(objectTypes: CampaignTypeDO[]): BudgetObjectType[] {
    return objectTypes ? objectTypes.map(CompanyDataService.convertBudgetObjectType) : [];
  }

  public static convertBudgetObjectType(objectType: CampaignTypeDO): BudgetObjectType {
    const budgetObject: BudgetObjectType = {
      id: objectType.id,
      name: objectType.name,
      isCustom: objectType.is_custom,
      createdDate: objectType.crd && new Date(objectType.crd),
      updatedDate: objectType.upd && new Date(objectType.upd),
      companyId: objectType.company
    };
  
    if ('is_enabled' in objectType) {
      budgetObject.isEnabled = objectType.is_enabled;
    }
  
    return budgetObject;
  }

  public static convertMetricObject(metric: MetricDO): MetricType {
    return {
      id: metric.id,
      companyId: metric.company,
      name: metric.name,
      isCustom: metric.is_custom,
      withCurrency: metric.with_currency,
      type: metric.type,
      revenuePerOutcome: metric.revenue_per_outcome,
      productId: metric.product,
      order: metric.order,
      isHidden: metric.is_hidden,
    };
  }

  static applyUsersLabels(currentUserList: UserDO[], selectedBudgetId: number): UserDO[] {
    return currentUserList.map(user => {
      const userName = user.user_profile_detail ? user.user_profile_detail.name : '';
      let label = '';

      if (user.disabled_in_company) {
        label = user.disabled_in_company ? ' (Inactive)' : '';
      } else if (selectedBudgetId)  {
        const perm = user.permissions.find(permission => permission.budget_id === selectedBudgetId);
        label = perm?.read && !perm?.read_write ? ' (Read-only)' : '';
      }
      user.name = userName + label;
      return user;
    })
  }

  public get selectedCompanySnapshot(): Company {
    return this.currentSelectedCompany;
  }

  public get selectedCompanyDOSnapshot(): CompanyDO {
    return this.selectedCompanyDO.value;
  }

  public get companyUsersSnapshot(): ExtendedUserDO[] {
    return this.currentUserList;
  }

  public get tagsSnapshot(): TagDO[] {
    return this.currentTagList;
  }

  public get goalTypesSnapshot(): BudgetObjectType[] {
    return this.currentGoalTypes;
  }

  public get campaignTypesSnapshot(): BudgetObjectType[] {
    return this.currentCampaignTypes;
  }

  public get programTypesSnapshot(): BudgetObjectType[] {
    return this.currentProgramTypes;
  }

  public get expenseTypesSnapshot(): BudgetObjectType[] {
    return this.currentExpenseTypes;
  }

  public get productsSnapshot(): ProductDO[] {
    return this.currentProducts;
  }

  public get metricsSnapshot(): MetricType[] {
    return this.currentMetrics;
  }

  public get vendorsSnapshot(): Vendor[] {
    return this.vendors$.getValue();
  }

  public get glCodesSnapshot(): GLCode[] {
    return this.currentGlCodes;
  }

  public get objectTypesSnapshot(): BudgetObjectType[] {
    return this.currentObjectTypes;
  }

  loadCompanyList(errorCb?: (error) => void) {
    this.commonService.getFilterCompanies()
      .pipe(map((data: any) => data.data as Company[]))
      .subscribe(
        data => {
          this.currentCompanyList = data;
          this.companyList.next(data);
        },
        error => errorCb?.(error)
      );
  }

  loadSelectedCompanyDetails(companyId: number) {
    this.companyService.getCompany(companyId)
      .subscribe(
        (data) => {
          // TEMPORARY WHILE BACKEND IS NOT READY
          data.currency_symbol = data.currency_symbol || '$';
          this.selectedCompanyDO.next(data);
        }
      );
  }

  resetData() {
    this.companyList.next(null);
    this.selectedCompany.next(null);
    this.selectedCompanyDO.next(null);
    this.resetMetricIntegrations();

    this.currentCompanyList = null;
    this.currentSelectedCompany = null;
    this.currentGoalTypes = null;
  }

  resetCompanyData() {
    this.currentCampaignTypes = null;
    this.currentProgramTypes = null;
    this.currentExpenseTypes = null;
    this.currentUserList = null;
    this.vendors$.next([]);
    this.currentGlCodes = null;
    this.currentProducts = null;
    this.currentMetrics = null;
    this.products.next(null);
    this.metrics.next(null);
    this.currentTagList = null;
  }

  updateSelectedCompany(companyId: number): void {
    const selectCompanyHandler = () => {
      const selectedCompany = this.currentCompanyList.find(company => company.id === companyId);
      if (this.currentSelectedCompany && (this.currentSelectedCompany !== selectedCompany)) {
        this.resetCompanyData();
      }
      if (selectedCompany != null) {
        this.setSelectedCompany(selectedCompany);
        this.currentSelectedCompany = selectedCompany;
      }
    }

    this.actionInterceptorsService.callInterceptors(CompanyServiceActionType.OnChangingSelectedCompany)
      .pipe(take(1), filter(allowedToProceed => allowedToProceed))
      .subscribe(() => selectCompanyHandler());
  }

  setSelectedCompany(company: Company): void {
    this.selectedCompany.next(company);
  }

  getInitialCompanyToSelect(userId): Company {
    let companyObj = null;
    if (this.currentCompanyList?.length > 0) {
      const storedCompanyId = this.selectedCompanyStorage.get(userId);
      companyObj =
        this.currentCompanyList.find(company => company.id === this.initCompanyId) ||
        this.currentCompanyList.find(company => company.id === storedCompanyId) ||
        this.currentCompanyList[0];
      this.initCompanyId = null;
    }
    return companyObj;
  }

  loadExpenseTypes(companyId: number, errorCb?: (error) => void) {
    this.expensesService.getExpenseTypes({ company: companyId })
      .pipe(map((data: any[]) => data && data.map(rawExpenseType => ({
        id: rawExpenseType.id,
        name: rawExpenseType.name,
        isCustom: rawExpenseType.is_custom,
        isEnabled: rawExpenseType.is_enabled,
        createdDate: rawExpenseType.crd && new Date(rawExpenseType.crd),
        updatedDate: rawExpenseType.upd && new Date(rawExpenseType.upd),
        status: rawExpenseType.status,
        companyId: rawExpenseType.company
      })) as BudgetObjectType[]))
      .subscribe(
        data => this.expenseTypes.next(this.currentExpenseTypes = data),
        error => errorCb && errorCb(error)
      );
  }

  loadCompanyUsers(companyId: number, errorCb?: (error) => void) {
    const companyUsersMap = {};
    const companyUsersDO$ = this.companyUserService.getCompanyUsers({ company: companyId })
      .pipe(
        tap(users => {
          this.companyUsersDO.next(users);
          users.forEach(user => {
            companyUsersMap[user.user] = user;
          });
        })
      );
    const users$ = this.expensesService.getUsers({ company: companyId })
      .pipe(
        map((data: UserDO[]) => data.map(user => {
          const companyUser: CompanyUserDO = companyUsersMap[user.id];
          const disabledInCompany = companyUser ? companyUser.status === CompanyUserStatus.Disabled : true;
          const userName = user.name || (user.user_profile_detail ? user.user_profile_detail.name : '');

          return {
            ...user,
            name: userName,
            permissions: companyUser.permissions,
            disabled_in_company: disabledInCompany,
            is_admin: companyUser.is_admin,
            is_account_owner: companyUser.is_account_owner,
          } as ExtendedUserDO;
        }))
      );

    companyUsersDO$
      .pipe(switchMap(() => users$))
      .subscribe(
        users => {
          this.users.next(this.currentUserList = users);
          this.emitCompanyUsersList();
        },
        error => errorCb && errorCb(error)
      );
  }

  emitCompanyUsersList() {
    this.companyUsersList.next(
      CompanyDataService.applyUsersLabels(this.currentUserList, this.selectedBudgetId)
        .filter(user => user.active_in_company || user.disabled_in_company)
    );
  }

  public updateUsersListForBudget(budgetId: number): void {
    this.selectedBudgetId = budgetId;
    if (!this.currentUserList) {
      return;
    }
    this.emitCompanyUsersList();
  }


  loadVendors(companyId: number, errorCb?: (error) => void) {
    this.expensesService.getVendors({ company: companyId })
      .subscribe(
        data => this.vendors$.next(data),
        error => errorCb && errorCb(error)
      );
  }

  createVendor(data: { company: number; name: string }): Observable<Vendor> {
    return this.expensesService.addVendor(data)
      .pipe(
        tap(createdVendor => this.vendors$.next([...this.vendorsSnapshot, createdVendor])),
      );
  }

  loadGlCodes(companyId: number, errorCb?: (error) => void) {
    this.expensesService.getGlCodes({ company: companyId })
      .pipe(map((data: any[]) => data && data.map(rawExpenseType => ({
        id: rawExpenseType.id,
        name: rawExpenseType.name,
        description: rawExpenseType.description,
        isEnabled: rawExpenseType.is_enabled,
        companyId: rawExpenseType.company
      })) as any[]))
      .subscribe(
        data => this.glCodes.next(this.currentGlCodes = data as GLCode[]),
        error => errorCb && errorCb(error)
      );
  }

  loadTags(companyId: number, errorCb?: (error) => void) {
    this.tagService.getTags({company: companyId})
      .subscribe(
        data => this.tags.next(this.currentTagList = data),
        error => errorCb && errorCb(error)
      );
  }

  loadTagMappings(companyId: number, params?: object, errorCb?: (error) => void) {
    this.tagService.getTagMappings(companyId, params)
      .subscribe(
        data => this.tagMappings.next(data as TagMapping[]),
        error => errorCb && errorCb(error)
      );
  }

  loadCompanyData(companyId: number, errorCb?: (error) => void) {
    [
      this.loadCampaignTypes,
      this.loadProgramTypes,
      this.loadExpenseTypes,
      this.loadCompanyUsers,
      this.loadVendors,
      this.loadGlCodes,
      this.loadProducts,
      this.loadMetrics,
      this.loadTags,
      this.loadGoalTypes
    ] .filter(loader => loader != null)
      .forEach(loader => loader.bind(this)(companyId, errorCb));
  }

  loadGoalTypes(companyId: number, errorCb?: (error) => void) {
    this.currentGoalTypes = null;
    this.goalService.getGoalTypes(companyId)
      .subscribe(
        (data: GoalTypeDO[]) =>
          this.goalTypes.next(
            this.currentGoalTypes = CompanyDataService.convertBudgetObjectTypes(data)
          ),
        error => errorCb && errorCb(error)
      );
  }

  loadCampaignTypes(companyId: number, errorCb?: (error) => void): void {
    this.campaignService.getCampaignTypes(companyId)
      .subscribe(
        (data: CampaignTypeDO[]) =>
          this.campaignTypes.next(
            this.currentCampaignTypes = CompanyDataService.convertBudgetObjectTypes(data)
          ),
        error => errorCb && errorCb(error)
      );
  }

  loadProgramTypes(companyId: number, errorCb?: (error) => void): void {
    this.programService.getProgramTypes(companyId)
      .subscribe(
        (data: ProgramTypeDO[]) =>
          this.programTypes.next(
            this.currentProgramTypes = CompanyDataService.convertBudgetObjectTypes(data)
          ),
        error => errorCb && errorCb(error)
      );
  }

  loadObjectTypes(companyId: number, errorCb?: (error) => void): void {
    this.objectTypeService.getObjectTypes(companyId)
      .subscribe({
          next: (data: ObjectTypeDO[]) => {
            this.currentObjectTypes = CompanyDataService.convertBudgetObjectTypes(data);
            this._budgetObjectTypes$.next(this.currentObjectTypes);
            // TODO: move campaignTypes and programTypes out of this subscription
            this.currentCampaignTypes = this.currentProgramTypes = this.currentObjectTypes;
            this.campaignTypes.next(this.currentCampaignTypes);
            this.programTypes.next(this.currentProgramTypes);
          },
          error: (error) => errorCb && errorCb(error)
      });
  }

  loadMetrics(companyId: number, errorCb?: (error) => void) {
    this.metricService.getMetrics(companyId)
      .subscribe(
        (data: MetricDO[]) =>
          this.metrics.next(
            this.currentMetrics = data?.map(CompanyDataService.convertMetricObject)
          ),
        error => errorCb?.(error)
      );
  }

  loadProducts(companyId: number, errorCb?: (error) => void) {
    this.productService.getProducts(companyId)
      .subscribe(
        (data: ProductDO[]) =>
          this.products.next(this.currentProducts = data),
        error => errorCb?.(error)
      );
  }

  get metricIntegrationsValue(): MetricIntegrations {
    return this.metricIntegrations.getValue();
  }

  get enabledMetricIntegrationsNames(): MetricIntegrationName[] {
    const metricIntegrations = this.metricIntegrationsValue;
    return Object.keys(metricIntegrations).filter(key => metricIntegrations[key]?.length) as MetricIntegrationName[];
  }

  public updateMetricIntegrations(integrationName: MetricIntegrationName, integration: Integration) {
    const allIntegrations = this.metricIntegrations.getValue();
    const integrationsBySource = allIntegrations[integrationName];
    const activeInd = integrationsBySource.findIndex(int => int.integrationId === integration.integrationId);
    if (activeInd === -1) {
      integrationsBySource.push(integration);
    } else {
      integrationsBySource[activeInd] = integration;
    }

    this.metricIntegrations.next({
      ...allIntegrations,
      [integrationName]: integrationsBySource,
    })
  }

  public deleteMetricIntegration(integrationName: MetricIntegrationName, integrationId: string) {
    const allIntegrations = this.metricIntegrations.getValue();
    const integrationsByType = allIntegrations[integrationName].filter(int => int.integrationId !== integrationId);
    this.metricIntegrations.next({
      ...allIntegrations,
      [integrationName]: integrationsByType,
    })
  }

  resetMetricIntegrations() {
    this.resetMetricIntegrations$.next();
    this.metricIntegrations.next(DEFAULT_INTEGRATIONS_STATUS);
  }

  public getMetricIntegrations(budget: Budget, companyDO: CompanyDO, errorCb?: (error) => void) {
    if (!budget || !companyDO) {
      return;
    }

    this.resetMetricIntegrations();

    const companyId = companyDO.id;
    const budgetId = budget.id;

    const getIntegrations$ =
      (integrationTurnedOn: boolean, provider$: Observable<Integration[]>) =>
        integrationTurnedOn ?
          provider$.pipe(
            catchError(() => of([]))
          ) :
          of([]);

    const salesforceIntegrations$ = getIntegrations$(companyDO.salesforce, this.salesforceService.getIntegrations(companyId));
    const hubspotIntegrations$ = getIntegrations$(companyDO.hubspot, this.hubspotService.getIntegrations(companyId));
    const googleAdsIntegrations$ = getIntegrations$(companyDO.google_ads, this.googleAdsDataService.getIntegrations(companyId, budgetId));
    const linkedinIntegrations$ = getIntegrations$(companyDO.linkedin_ads, this.linkedInDataService.getIntegrations(companyId, budgetId));
    const facebookIntegrations$ = getIntegrations$(companyDO.facebook_ads, this.facebookDataService.getIntegrations(companyId, budgetId));

    forkJoin([ salesforceIntegrations$, hubspotIntegrations$, googleAdsIntegrations$, linkedinIntegrations$, facebookIntegrations$ ])
      .pipe(
        takeUntil(this.resetMetricIntegrations$)
      )
      .subscribe(
        ([
           salesforceIntegrations,
           hubspotIntegrations,
           googleAdsIntegrations,
           linkedinIntegrations,
           facebookIntegrations
         ]) => {
          this.metricIntegrations.next({
            [MetricIntegrationName.Salesforce]: salesforceIntegrations || [],
            [MetricIntegrationName.Hubspot]: hubspotIntegrations || [],
            [MetricIntegrationName.GoogleAds]: googleAdsIntegrations || [],
            [MetricIntegrationName.LinkedinAds]: linkedinIntegrations || [],
            [MetricIntegrationName.FacebookAds]: facebookIntegrations || [],
          });
        },
        error => errorCb?.(error)
      )
  }

  initWithCompany(companyId: number) {
    this.initCompanyId = companyId;
  }
}
