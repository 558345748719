import { Component, inject, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { FilterManagementService } from 'app/header-navigation/components/filters/filter-services/filter-management.service';
import { Configuration } from 'app/app.constants';
import { AppRoutingService } from '@shared/services/app-routing.service';

@Component({
  selector: 'navigation-toast',
  templateUrl: './navigation-toast.component.html',
  styleUrls: ['./navigation-toast.component.scss'],
})
export class NavigationToastComponent {
  private readonly router = inject(Router);
  private readonly filterManagementService = inject(FilterManagementService);
  private readonly configuration = inject(Configuration);
  private readonly appRoutingService = inject(AppRoutingService);

  @Input() pageName: string;
  @Input() redirectUrl: string;
  @Input() drawerUrl: string;
  @Input() filterSet: Record<string, any>;
  @Input() closeSubject$: Subject<void>;
  @Input() queryParams: any;

  private readonly defaultNavText = 'Go back';
  protected navText = this.defaultNavText;

  ngOnChange() {
    const navToName = this.pageName ? ` to ${this.pageName}` : '';
    this.navText = `${this.defaultNavText}${navToName}`;
  }

  navigateBack(): void {
    
    // Close any open drawer on GoBack if the redirect is to the attributes-tags page
    if(this.redirectUrl.includes("/attributes-tags") && this.appRoutingService.isDrawerOpen()) {
      this.appRoutingService.closeActiveDrawer();
    }

    const drawerRedirect = this.drawerUrl
        ? this.router.navigate(
            [{ outlets: { [this.configuration.ROUTER_OUTLETS.DRAWER_STACK]: this.drawerUrl } }],
            { skipLocationChange: true, queryParamsHandling: 'preserve' }
          )
        : Promise.resolve();

    drawerRedirect.then(() => {
        if (this.redirectUrl) {
            this.filterManagementService.updateCurrentFilterSet(this.filterSet);
            const navigationExtras: any = {
                skipLocationChange: true,
                state: { shouldRestoreState: true }
            };

            // Conditionally add queryParams if they exist
            if (this.queryParams) {
                navigationExtras.queryParams = this.queryParams;
            }

            this.router.navigate([this.redirectUrl], navigationExtras);
        }
    });

    this.closeSubject$.next();
  }

}
