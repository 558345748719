import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Budget, BudgetTimeframesType } from 'app/shared/types/budget.interface';
import { GoalsService } from 'app/shared/services/backend/goals.service';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { BudgetSegmentAccess, BudgetSegmentAccessResponse } from 'app/shared/types/segment.interface';
import { Goal } from 'app/shared/types/goal.interface';
import { Campaign, CampaignAllocationDO, CampaignDO, LightCampaign } from 'app/shared/types/campaign.interface';
import { LightProgram, Program, ProgramDO } from 'app/shared/types/program.interface';
import { BudgetObject } from 'app/shared/types/budget-object.interface';
import {
  CampaignTimeframe,
  ObjectTimeframe,
  ProgramTimeframe,
  SegmentedObjectTimeframe
} from 'app/shared/types/object-timeframe.interface';
import { SharedCostRule } from 'app/shared/types/shared-cost-rule.interface';
import { SegmentedBudgetObject } from 'app/shared/types/segmented-budget-object';
import { Configuration } from 'app/app.constants';
import { SharedCostRulesService } from 'app/shared/services/backend/shared-cost-rules.service';
import { filter, map, take } from 'rxjs/operators';
import { CampaignService } from 'app/shared/services/backend/campaign.service';
import { BudgetAllocationService } from 'app/shared/services/backend/budget-allocation.service';
import { BudgetSegmentService } from 'app/shared/services/backend/budget-segment.service';
import { ProgramAllocation } from 'app/shared/types/budget-object-allocation.interface';
import { defineTimeframe } from 'app/shared/utils/budget.utils';
import { BudgetService } from 'app/shared/services/backend/budget.service';
import { ActionInterceptorsService } from 'app/shared/services/action-interceptors.service';
import { SegmentGroup, SegmentGroupDO } from 'app/shared/types/segment-group.interface';
import { SegmentGroupService } from 'app/shared/services/backend/segment-group.service';
import { generateGUID, getPseudoObjectId } from 'app/shared/utils/common.utils';
import { IsSharedSubObjectPipe } from '../is-shared-subobject.pipe';
import { parseDateString } from 'app/budget-object-details/components/containers/campaign-details/date-operations';
import { BudgetServiceActionType } from '@shared/enums/interceptor-action-type.enum';
import { ProgramService } from '@shared/services/backend/program.service';
import { CompanyDataService } from '@shared/services/company-data.service';
import { ManagePageExportParams } from '@manage-ceg/types/manage-ceg-page.types';
import { ExternalIntegrationExpenseSource } from '../../shared/constants/external-integration-object-types';

@Injectable()
export class BudgetDataService {
  private readonly goalsService = inject(GoalsService);
  private readonly campaignService = inject(CampaignService);
  private readonly programService = inject(ProgramService);
  private readonly configuration = inject(Configuration);
  private readonly sharedCostRulesService = inject(SharedCostRulesService);
  private readonly budgetAllocationService = inject(BudgetAllocationService);
  private readonly budgetSegmentService = inject(BudgetSegmentService);
  private readonly budgetService = inject(BudgetService);
  private readonly actionInterceptorsService = inject(ActionInterceptorsService);
  private readonly segmentGroupService = inject(SegmentGroupService);
  private readonly isSharedSubObjectPipe = inject(IsSharedSubObjectPipe);
  private readonly companyDataService = inject(CompanyDataService);

  private readonly userIdPlaceholder = '{userId}';
  private readonly selectedBudgetLSKey = `selectedBudget_${this.userIdPlaceholder}`;

  private readonly companyBudgetList = new Subject<Budget[]>();
  private readonly selectedBudget = new Subject<Budget>();
  private readonly timeframeList = new BehaviorSubject<BudgetTimeframe[]>(null);
  private readonly segmentGroupList = new Subject<SegmentGroup[]>();
  private readonly segmentList = new Subject<BudgetSegmentAccess[]>();
  private readonly sharedCostRuleList = new Subject<SharedCostRule[]>();
  private readonly goalList = new Subject<Goal[]>();
  private readonly campaignList = new Subject<Campaign[]>();
  private readonly lightCampaignList = new Subject<LightCampaign[]>();
  private readonly programList = new Subject<Program[]>();
  private readonly lightProgramList = new Subject<LightProgram[]>();
  private readonly poNumbers = new BehaviorSubject<string[]>(null);

  public companyBudgetList$ = this.companyBudgetList.asObservable();
  public selectedBudget$ = this.selectedBudget.asObservable();
  public timeframeList$: Observable<BudgetTimeframe[]> = this.timeframeList.asObservable().pipe(
    filter(timeframes => !!timeframes)
  );
  public segmentGroupList$ = this.segmentGroupList.asObservable();
  public segmentList$ = this.segmentList.asObservable();
  public sharedCostRuleList$ = this.sharedCostRuleList.asObservable();
  public goalList$ = this.goalList.asObservable();
  public campaignList$ = this.campaignList.asObservable();
  public lightCampaignList$ = this.lightCampaignList.asObservable();
  public programList$ = this.programList.asObservable();
  public lightProgramList$ = this.lightProgramList.asObservable();
  public initBudgetId: number;
  public poNumbers$ = this.poNumbers.asObservable();

  private currentBudgetList: Budget[] = [];
  private currentSelectedBudget: Budget;

  private currentGoals: Goal[];
  private currentCampaigns: Campaign[];
  private currentLightCampaigns: LightCampaign[];
  private currentPrograms: Program[];
  private currentLightPrograms: LightProgram[];
  private currentSegmentGroups: SegmentGroup[];
  private currentSegments: BudgetSegmentAccess[];
  private currentSharedCostRules: SharedCostRule[];
  public allSegmentsAvailable: boolean;

  public readonly selectedBudgetStorage = {

    getStoredCompanyBudgetMap: (userId: number): Record<string, string> => {
      let storedCompanyBudgetMap = this.selectedBudgetStorage.get(userId);
      return storedCompanyBudgetMap ? JSON.parse(storedCompanyBudgetMap) : {}
    },

    getSelectedCompany: (userId: number) => {
      return this.companyDataService.selectedCompanyStorage.get(userId);
    },

    save: (userId: number, budgetId: number) => {
      const key = this.selectedBudgetStorage.getStorageKey(userId);
      let storedCompanyBudgetMap = this.selectedBudgetStorage.getStoredCompanyBudgetMap(userId);
      let selectedCompany = this.selectedBudgetStorage.getSelectedCompany(userId);

      const newStoredCompanyBudgetMap = {
        ...storedCompanyBudgetMap,
        [selectedCompany]: budgetId.toString()
      }

      localStorage.setItem(key, JSON.stringify(newStoredCompanyBudgetMap) )

    },
    get: (userId: number) => {
      const key = this.selectedBudgetStorage.getStorageKey(userId);
      const value = localStorage.getItem(key);
      return value
    },
    remove: (userId: number, budgetId?: number) => {
      if (this.selectedBudgetStorage.get(userId) != null) {
        const key = this.selectedBudgetStorage.getStorageKey(userId);
        if(!budgetId) {
          localStorage.removeItem(key);
        } else {
          let storedCompanyBudgetMap = this.selectedBudgetStorage.getStoredCompanyBudgetMap(userId);
          let selectedCompany = this.selectedBudgetStorage.getSelectedCompany(userId);

          const newStoredCompanyBudgetMap = Object.keys(storedCompanyBudgetMap)
          .filter(company => parseInt(company, 10) !== selectedCompany)
          .reduce((object, company) => {
            return Object.assign(object, { [company]: storedCompanyBudgetMap[company] })
          }, {})

          localStorage.setItem(key, JSON.stringify(newStoredCompanyBudgetMap) )

        }

      }
    },
    getStorageKey: userId => this.selectedBudgetLSKey.replace(this.userIdPlaceholder, userId)
  };

  private static generateSubObjectId<T extends CampaignDO | ProgramDO>(objectDO: T, sourceObjectIdSelector: (obj: T) => number): string {
    return getPseudoObjectId(sourceObjectIdSelector(objectDO), objectDO.company_budget_segment1);
  }

  public loadCompanyBudgetList(companyId: number, errorCb?: (error) => void) {
    this.emptySnapshotValues();
    this.getCompanyAvailableBudgetList(companyId)
      .subscribe(
        data => {
          this.currentBudgetList = data;
          this.companyBudgetList.next(data);
        },
        error => errorCb?.(error));
  }

  public selectBudget(budgetId: number, onSelected?: () => void): void {
    const selectBudgetHandler = () => {
      const selectedBudget = this.currentBudgetList.find(budget => budget.id === budgetId);
      if (selectedBudget != null) {
        this.currentSelectedBudget = selectedBudget;
        this.selectedBudget.next(selectedBudget);
        if (selectedBudget.new_campaigns_programs_structure) {
          this.companyDataService.loadObjectTypes(selectedBudget.company);
        }
      }
      onSelected?.();
    };

    // If budget ID hasn't changed proceed without interceptors
    if (budgetId === this.currentSelectedBudget?.id) {
      selectBudgetHandler();
      return;
    }

    this.actionInterceptorsService.callInterceptors(BudgetServiceActionType.OnChangingSelectedBudget)
      .pipe(take(1), filter(allowedToProceed => allowedToProceed))
      .subscribe(() => selectBudgetHandler());
  }

  public onBudgetCreated(budget: Budget) {
    const updBudgetsList = [ ...this.budgetListSnapshot, budget ];
    this.currentBudgetList = [ ...updBudgetsList ];
    this.companyBudgetList.next(this.currentBudgetList);
    this.selectBudget(budget.id);
  }

  public refreshBudgetInList(budget: Budget) {
    const updBudgetsList = [ ...this.budgetListSnapshot ];
    const index = updBudgetsList.findIndex(el => el.id === budget.id);
    if (index >= 0) {
      updBudgetsList[index] = budget;
      this.currentBudgetList = [...updBudgetsList];
      this.companyBudgetList.next(this.currentBudgetList);
    }
  }

  public removeBudgetFromList(budgetId: number) {
    const updBudgetsList = this.budgetListSnapshot.filter(budget => budget.id !== budgetId);
    this.currentBudgetList = [ ...updBudgetsList ];
    this.companyBudgetList.next(this.currentBudgetList);
  }

  public get budgetListSnapshot(): Budget[] {
    return this.currentBudgetList;
  }

  public get selectedBudgetSnapshot(): Budget {
    return this.currentSelectedBudget;
  }

  public get timeframesSnapshot(): BudgetTimeframe[] {
    return this.timeframeList.getValue();
  }

  public get goalsSnapshot(): Goal[] {
    return this.currentGoals;
  }

  public get campaignsSnapshot(): Campaign[] {
    return this.currentCampaigns;
  }

  public get lightCampaignsSnapshot(): LightCampaign[] {
    return this.currentLightCampaigns;
  }

  public get programsSnapshot(): Program[] {
    return this.currentPrograms;
  }

  public get lightProgramsSnapshot(): LightProgram[] {
    return this.currentLightPrograms;
  }

  public get segmentsSnapshot(): BudgetSegmentAccess[] {
    return this.currentSegments;
  }

  public get segmentGroupsSnapshot(): SegmentGroup[] {
    return this.currentSegmentGroups;
  }

  public get sharedCostRulesSnapshot(): SharedCostRule[] {
    return this.currentSharedCostRules;
  }

  public get currentTimeframe(): BudgetTimeframe {
    if (this.timeframesSnapshot && this.currentSelectedBudget) {
      return defineTimeframe(this.timeframesSnapshot, this.currentSelectedBudget);
    }
  }

  public get currentQuarter(): BudgetTimeframe[] {
    if (this.currentTimeframe) {
      const {type} = this.selectedBudgetSnapshot;

      if (type === BudgetTimeframesType.Quarter) {
        return [this.currentTimeframe];
      }

      if (type === BudgetTimeframesType.Month) {
        const tfIndex = this.timeframesSnapshot.findIndex(
          tf => tf.id === this.currentTimeframe.id
        );
        return this.timeframesSnapshot.filter(
          (el, i) => tfIndex % 3 === 0
            ? i >= tfIndex && i <= (tfIndex + 2)
            : tfIndex % 3 === 1 ? i >= (tfIndex - 1) && i <= (tfIndex + 1)
            : i >= (tfIndex - 2) && i <= (tfIndex)
        );
      }
    }
  }

  public get overdueTimeframes(): BudgetTimeframe[] {
    if (this.currentTimeframe) {
      const tfIndex = this.timeframesSnapshot.findIndex(
        tf => tf.id === this.currentTimeframe.id
      );
      return this.timeframesSnapshot.slice(0, tfIndex);
    } else {
      return [...this.timeframesSnapshot];
    }
  }

  public get isCurrentBudgetWithNewCEGStructure(): boolean {
    return this.currentSelectedBudget?.new_campaigns_programs_structure;
  }

  public get isCompanyWithDefaultNewCEGStructure(): boolean {
    return this.companyDataService.selectedCompanyDOSnapshot?.default_for_new_campaigns_programs_structure;
  }

  public setNoBudget() {
    this.selectedBudget.next(null);
  }

  public loadSegmentGroups(budgetId: number, errorCb?: (error, msg?: string) => void) {
    this.currentSegmentGroups = null;
    this.segmentGroupService.getSegmentGroups(budgetId)
      .pipe(
        map((data: SegmentGroupDO[]): SegmentGroup[] => (
          data.map(groupDO => ({
            id: groupDO.id,
            name: groupDO.name,
            owner: groupDO.owner,
            budget: groupDO.budget,
            crd: groupDO.crd,
            upd: groupDO.upd,
            numberOfSegments: groupDO.number_of_segments,
            key: generateGUID()
          }))
        ))
      )
      .subscribe(
        (data: SegmentGroup[]) => {
          this.segmentGroupList.next(this.currentSegmentGroups = data);
        },
        error => errorCb && errorCb(error, 'Failed to load segment groups')
      );
  }

  public loadBudgetTimeframes(budgetId: number, errorCb?: (error, msg?: string) => void) {
    this.timeframeList.next(null);
    this.budgetAllocationService.getBudgetAllocations(budgetId)
      .subscribe(
        (data: BudgetTimeframe[]) => this.timeframeList.next(data),
        error => errorCb && errorCb(error, 'Failed to load budget allocations')
      );
  }

  public loadAvailableBudgetSegments(budgetId: number, errorCb?: (error, msg?: string) => void) {
    this.currentSegments = null;
    this.allSegmentsAvailable = null;
    this.getAvailableBudgetSegments(budgetId)
      .subscribe(
        data => {
          const availableSegments = data.available_segments;
          this.allSegmentsAvailable = data.total_number === availableSegments.length;
          this.segmentList.next(this.currentSegments = availableSegments);
        },
        error => errorCb && errorCb(error, 'Failed to load budget segments')
      );
  }

  public loadBudgetSharedCostRules(companyId: number, budgetId: number, errorCb?: (error) => void) {
    this.currentSharedCostRules = null;
    this.sharedCostRulesService.getAllRules({ company: companyId, budget: budgetId, is_active: true })
      .subscribe(
        data => this.sharedCostRuleList.next(this.currentSharedCostRules = data as SharedCostRule[]),
        error => errorCb && errorCb(error)
      );
  }

  public loadGoals(companyId: number, budgetId: number, status = this.configuration.goalStatusNames.active, errorCb?: (error) => void) {
    this.currentGoals = null;
    this.getGoals(companyId, budgetId, status)
      .subscribe(
        data => this.goalList.next(this.currentGoals = data as Goal[]),
        error => errorCb && errorCb(error)
      );
  }

  public loadCampaigns(
    companyId: number,
    budgetId: number,
    status = this.configuration.campaignStatusNames.active,
    errorCb?: (error) => void,
    params?: Object
  ): void {
    this.currentCampaigns = null;
    this.getCampaigns(companyId, budgetId, status, params)
      .subscribe({
        next: data => this.campaignList.next(this.currentCampaigns = data),
        error: err => errorCb?.(err)
      });
  }

  public loadLightCampaigns(
    companyId: number,
    budgetId: number,
    status = this.configuration.campaignStatusNames.active,
    errorCb?: (error) => void,
    params?: Object
  ): void {
    this.currentLightCampaigns = null;
    this.getLightCampaigns(companyId, budgetId, status, params)
      .subscribe({
        next: data => {
          this.currentLightCampaigns = data;
          this.lightCampaignList.next(data);
          },
        error: err => errorCb?.(err)
      });
  }

  public addCampaignToSnapshot(item: Campaign): void {
    this.currentCampaigns?.push(item);
    this.currentLightCampaigns?.push(item);
    this.lightCampaignList.next(this.currentLightCampaigns);
  }

  public loadPrograms(
    companyId: number,
    budgetId: number,
    status = this.configuration.programStatusNames.active,
    errorCb?: (error) => void,
    params?: Object
  ) {
    this.currentPrograms = null;
    this.getPrograms(companyId, budgetId, status, params)
      .subscribe({
        next: data => this.programList.next(this.currentPrograms = data),
        error: err => errorCb?.(err)
      });
  }

  public fetchShortProgramList(
    companyId: number,
    budgetId: number,
    status = this.configuration.programStatusNames.active,
    errorCb?: (error) => void,
    filterParams?: Object
  ): void {
    filterParams = { short: true, ...filterParams };
    const params = {
      ...(filterParams || {}),
      'company': companyId,
      'budget': budgetId,
      'status': status
    };

    this.programService.listPrograms(params)
      .pipe(
        map((data: any[]) => data?.map(rawProgramObj => this.convertProgram(rawProgramObj, params['short'])))
      ).subscribe({
        next: data => this.lightProgramList.next(this.currentLightPrograms = data),
        error: err => errorCb?.(err)
      });
  }

  public loadLightPrograms(
    companyId: number,
    budgetId: number,
    status = this.configuration.programStatusNames.active,
    errorCb?: (error) => void,
    params?: Object
  ) {
    this.currentLightPrograms = null;
    this.getLightPrograms(companyId, budgetId, status, params)
      .subscribe({
        next: data => this.lightProgramList.next(this.currentLightPrograms = data),
        error: err => errorCb?.(err)
      });
  }

  public addProgramToSnapshot(item: Program): void {
    this.currentPrograms?.push(item);
    this.currentLightPrograms?.push(item);
    this.lightProgramList.next(this.currentLightPrograms);
  }

  public getCompanyAvailableBudgetList(companyId: number) {
    return this.budgetService.getAvailableBudgetsForCompany(companyId);
  }

  public getAvailableBudgetSegments(budgetId: number): Observable<BudgetSegmentAccessResponse> {
    return this.budgetSegmentService.getAvailableBudgetSegments(budgetId);
  }

  public getGoals(
    companyId: number,
    budgetId: number,
    status = this.configuration.goalStatusNames.active,
    filterParams: object = null
  ) {
    const params = {
      ...(filterParams || {}),
      'company': companyId,
      'budget': budgetId,
      'status': status
    };

    return this.goalsService.getGoals(params)
      .pipe(map((data: any[]) => data && data.map(rawGoalObj => this.convertGoal(rawGoalObj))));
  }

  public fetchShortCampaignList(
    companyId: number,
    budgetId: number,
    status = this.configuration.campaignStatusNames.active,
    filterParams: object = null,
    errorCb?: (error) => void
  ): void {
    filterParams = { short: true, ...filterParams };
    const params = {
      ...(filterParams || {}),
      'company': companyId,
      'budget': budgetId,
      'status': status
    };

    this.campaignService.listCampaigns(params)
      .pipe(
        map((data: CampaignDO[]) => data?.map(rawCampaignObj => this.convertCampaignDO(rawCampaignObj, params['short'])))
      ).subscribe({
        next: data => {
          this.currentLightCampaigns = data;
          this.lightCampaignList.next(data);
          },
        error: err => errorCb?.(err)
      });
  }

  public getCampaigns(
    companyId: number,
    budgetId: number,
    status = this.configuration.campaignStatusNames.active,
    filterParams: object = null
  ): Observable<Campaign[]> {
    const params = {
      ...(filterParams || {}),
      'company': companyId,
      'budget': budgetId,
      'status': status
    };

    return this.campaignService.getCampaigns(params)
      .pipe(
        map((data: CampaignDO[]) => data?.map(rawCampaignObj => this.convertCampaignDO(rawCampaignObj, params['short'])))
      );
  }

  public getLightCampaigns(
    companyId: number,
    budgetId: number,
    status = this.configuration.campaignStatusNames.active,
    filterParams: object = null
  ): Observable<LightCampaign[]> {
    return this.getCampaigns(companyId, budgetId, status, { short: true, ...filterParams });
  }

  public setLightCampaigns(campaigns: LightCampaign[]): void {
    this.currentLightCampaigns = campaigns;
    this.lightCampaignList.next(campaigns);
  }

  public getPrograms(
    companyId: number,
    budgetId: number,
    status = this.configuration.programStatusNames.active,
    filterParams: object = null
  ): Observable<Program[]> {
    const params = {
      ...(filterParams || {}),
      'company': companyId,
      'budget': budgetId,
      'status': status
    };

    return this.programService.getPrograms(params)
      .pipe(
        map((data: any[]) => data?.map(rawProgramObj => this.convertProgram(rawProgramObj, params['short'])))
      );
  }

  public getLightPrograms(
    companyId: number,
    budgetId: number,
    status = this.configuration.programStatusNames.active,
    filterParams: object = null
  ): Observable<LightProgram[]> {
    return this.getPrograms(companyId, budgetId, status, { short: true, ...filterParams });
  }

  public setLightPrograms(programs: LightProgram[]): void {
    this.currentLightPrograms = programs;
    this.lightProgramList.next(programs);
  }

  public exportManagePage(budgetId: number, params: Partial<ManagePageExportParams>): Observable<any> {
    return this.budgetService.exportManagePage(budgetId, params);
  }

  private getBudgetObject(rawObject: any): BudgetObject {
    return {
      id: rawObject.id,
      name: rawObject.name,
      companyId: rawObject.company,
      budgetId: rawObject.budget,
      createdBy: rawObject.created_by,
      createdDate: rawObject.crd ? new Date(rawObject.crd) : rawObject.crd,
      updatedDate: rawObject.upd ? new Date(rawObject.upd) : rawObject.upd,
      status: rawObject.status,
      notes: rawObject.note,
      statusTotals: rawObject.status_totals,
      timeframes: [] // Will be overridden in concrete object
    };
  }

  public convertGoal(rawObject: any): Goal {
    return {
      ...this.getBudgetObject(rawObject),
      goalTypeId: rawObject.goal_type
    };
  }

  public setGoals(goals: Goal[]) {
    this.currentGoals = goals;
    this.goalList.next(goals);
  }

  public convertCampaignDO(rawObject: CampaignDO, isShort = false): Campaign {
    const campaign = {
      isShort,
      ...this.getSegmentedObject(rawObject),
      campaignTypeId: rawObject.campaign_type || rawObject.object_type,
      startDate: rawObject.start_date && parseDateString(rawObject.start_date),
      endDate: rawObject.end_date && parseDateString(rawObject.end_date),
      timeframes: this.getCampaignTimeframes(rawObject.campaign_allocations),
      goalId: rawObject.goal,
      parentCampaign: rawObject.parent_campaign,
      subCampaignId: rawObject.campaign_id,
      keyMetric: rawObject.key_metric,
      campaign_integrated_source : 'source' in rawObject ? Object.values(ExternalIntegrationExpenseSource).includes(rawObject.source) : rawObject.campaign_integrated_source
    };

    campaign.isPseudoObject = this.isSharedSubObjectPipe.transform(rawObject);
    campaign.objectId =
      campaign.isPseudoObject ?
        BudgetDataService.generateSubObjectId(rawObject, obj => obj.campaign_id) :
        campaign.id;
    return campaign;
  }

  private getCampaignTimeframes(rawCampaignAllocs: CampaignAllocationDO[]): CampaignTimeframe[] {
    return rawCampaignAllocs ?
      rawCampaignAllocs.map(alloc =>
        ({...this.getSegmentedObjectTimeframe(alloc), campaignId: alloc.campaign})
      ) :
      [];
  }

  public convertProgram(rawObject: ProgramDO, isShort = false): Program {
    const program = {
      isShort,
      ...this.getSegmentedObject(rawObject),
      programTypeId: rawObject.program_type || rawObject.object_type,
      timeframes: this.getProgramTimeframes(rawObject.program_allocations),
      campaignId: rawObject.campaign || rawObject.campaign_id,
      goalId: rawObject.goal,
      glCode: rawObject.gl_code,
      poNumber: rawObject.po_number,
      subProgramId: rawObject.program_id || rawObject.program
    };
    program.isPseudoObject = this.isSharedSubObjectPipe.transform(rawObject);
    program.objectId =
      program.isPseudoObject ?
        BudgetDataService.generateSubObjectId(rawObject, obj => obj.program_id || obj.program) :
        program.id;
    return program;
  }

  loadPoNumbers(budgetId: number, errorCb?: (error) => void): void {
    this.programService.getPoNumbers(budgetId)
      .subscribe({
        next: data => this.poNumbers.next(data),
        error: error => errorCb && errorCb(error)
      });
  }

  private getProgramTimeframes(rawProgramAllocs: ProgramAllocation[]): ProgramTimeframe[] {
    return rawProgramAllocs ?
      rawProgramAllocs.map(alloc =>
        ({...this.getSegmentedObjectTimeframe(alloc), programId: alloc.program})
      ) :
      [];
  }

  private getSegmentedObject(rawObject: CampaignDO | ProgramDO): SegmentedBudgetObject {
    const budgetObject = this.getBudgetObject(rawObject);
    return {
      ...budgetObject,
      objectId: budgetObject.id,
      externalId: rawObject.external_id,
      budgetSegmentId: rawObject.company_budget_segment1 != null
        ? Number(rawObject.company_budget_segment1)
        : rawObject.company_budget_segment1,
      splitRuleId: rawObject.split_rule != null
        ? Number(rawObject.split_rule)
        : rawObject.split_rule,
      ownerId: rawObject.owner,
      amount: rawObject.amount,
      mode: rawObject.mode,
      timeframes: [], // Will be overridden in concrete objects,
      isPseudoObject: false,
      amountStatus: rawObject.amount_status,
      currencyCode: rawObject.source_currency
    };
  }

  private getSegmentedObjectTimeframe(rawAlloc): SegmentedObjectTimeframe {
    return {
      ...this.getObjectTimeframe(rawAlloc),
      mode: rawAlloc.mode,
      amount: rawAlloc.amount
    };
  }

  private getObjectTimeframe(alloc): ObjectTimeframe {
    return {
      id: alloc.id,
      createdDate: alloc.crd ? new Date(alloc.crd) : alloc.crd,
      updatedDate: alloc.upd ? new Date(alloc.upd) : alloc.upd,
      company_budget_alloc: alloc.company_budget_alloc,
      companyId: alloc.company
    };
  }

  private emptySnapshotValues() {
    this.currentBudgetList = [];
    this.currentSelectedBudget = null;
    this.currentGoals = null;
    this.currentCampaigns = null;
    this.currentLightCampaigns = null;
    this.currentPrograms = null;
    this.currentLightPrograms = null;
    this.currentSegmentGroups = null;
    this.currentSegments = null;
    this.currentSharedCostRules = null;
  }
}
