import { SelectedValue, SelectItem } from 'app/shared/types/select-groups.interface';
import { DIALOG_ACTION_TYPE, DialogAction } from '@shared/types/dialog-context.interface';

export interface FilterSourceObject {
  id: SelectedValue;
  name: string;
  isEnabled?: boolean;
  is_enabled?: boolean;
}

export enum FilterName {
  Timeframes = 'timeframes',
  Segments = 'segments',
  Goals = 'goals',
  Statuses = 'statuses',
  Tags = 'tags',
  Campaigns = 'campaigns',
  CampaignTypes = 'campaignTypes',
  ExpenseBuckets = 'expenseBuckets',
  ExpenseTypes = 'expenseTypes',
  Owners = 'owners',
  GlCodes = 'glCodes',
  Vendors = 'vendors',
  SharedCostRules = 'sharedCostRules',
  ExpenseSource = 'expenseSource',
  Metrics = 'metrics',
  PONumber = 'poNumber',
  CEGStatus = 'status',
  GoalTypes = 'goalTypes',
}

export interface FilterSet {
  [key: string]: SelectedValue[];
}

export interface InnerFilterSet {
  [key: string]: SelectItem[] | any[];
}

export enum FilterType {
  CEGFilter = 'CEGFilter',
  CustomFieldFilter = 'CustomFieldFilter',
} 

export interface Filter {
  fieldName: FilterName | string ;
  title: string;
  plural: string;
  hideSearch?: boolean;
  skipGroupsSelection?: boolean;
  availableItems: SelectItem[];
  type?: FilterType;
}

export enum CustomFieldFiltersMappingType {
  Manage = "Campaign,Program",
  Expense = "Expense",
  Calendar = "Campaign",
}

export enum CustomFieldFilterPageRoute {
  Manage = 'Manage',
  Expense = 'Expense',
  Invoice = 'Invoice', 
  Calendar = 'Calendar',
}

export enum FilterPageRoute {
  Invoice = '/spending/invoices',
  Expenses = '/spending/expenses',
  Manage = '/manage_ceg/budget',
  Dashboard = '/dashboard',
  Calendar = '/calendar',
}

export interface FilterSetData {
  id: number;
  name: string;
  ownerId: number;
  budgetId: number;
  isPublic: boolean;
  isFavourite: boolean;
  selectedFilters: FilterSet;
}

export interface ObjectLocalFilter<T> {
  satisfies(object: T): boolean
}

export interface OnFilterChangeEvent {
  fieldName: FilterName | string;
  values: SelectItem[];
}

export interface FilterDialogAction extends Omit<DialogAction, 'handler'> {
  handler: (data: any, unfavouriteId) => void;
}

export interface FilterDialogContext {
  title: string;
  state: FilterDialogState;
  context?: {
    onSelectActive?: (selectedFilterId: number) => void;
    onRemove?: (details) => void;
    onUpdateFilterSet?: (FilterSetData, unfavouriteId) => void;
    budgetData: { id: number, name: string }
    owner: { id: number, name: string }
    savedFilters?: FilterSetData[];
    selectedFilterId?: number;
    companyUsers?: {[id: string]: string};
    favouriteFilter?: FilterSetData;
  };
  submitAction?: FilterDialogAction;
  cancelAction?: DialogAction;
  deleteAction?: DialogAction;
  width?: string;
}

export enum FilterDialogState {
  Save = 'Save',
  Edit = 'Edit',
  Select = 'Select'
}

export const FilterDialogConfig = {
  [FilterDialogState.Save]: {
    title: 'Save and Apply Filter',
    submitAction: { label: 'Save', handler: null, type: DIALOG_ACTION_TYPE.FLAT },
    cancelAction: { label: 'Cancel', handler: null, type: DIALOG_ACTION_TYPE.DEFAULT },
    state: FilterDialogState.Save,
    width: '480px',
  },
  [FilterDialogState.Edit]: {
    title: 'Edit Filter',
    submitAction: { label: 'Save', handler: null, type: DIALOG_ACTION_TYPE.FLAT },
    deleteAction: { label: 'Delete Filter', handler: null, type: DIALOG_ACTION_TYPE.DEFAULT },
    cancelAction: { label: 'Cancel', handler: null, type: DIALOG_ACTION_TYPE.DEFAULT },
    state: FilterDialogState.Edit,
    width: '480px',
  },
  [FilterDialogState.Select]: {
    title: 'Select saved filter',
    submitAction: { label: 'Apply', handler: null, type: DIALOG_ACTION_TYPE.FLAT },
    cancelAction: { label: 'Cancel', handler: null, type: DIALOG_ACTION_TYPE.DEFAULT },
    state: FilterDialogState.Select,
    width: '500px',
  }
}
