<div class="attribute-page-container">
  <page-header pageName="Attributes & Tags" [isAdminPage]="true">
    <icon-attributes slot="icon" size="30px" [darkIcon]="true"></icon-attributes>
  </page-header>

  <div class="attribute-page-content">
    <div class="page-container">
      <div class="tabs-header">
        <tab-switch
        [options]="dataModeOptions"
        [selectedValue]="dataMode"
        (onChange)="setDataMode($event)"
        [autoMinWidth]="true"></tab-switch>
      </div>

      <div class="grid-container" [ngSwitch]="dataMode">
        <div *ngSwitchCase="'groupTypes'">
          <tab-campaign-expense-group-type [companyId]="companyId" [budgetId]="selectedBudget?.id"></tab-campaign-expense-group-type>
        </div>
        <div *ngSwitchCase="'expenseTypes'">
          <tab-expense-types [companyId]="companyId" [budgetId]="selectedBudget?.id"></tab-expense-types>
        </div>
        <div *ngSwitchCase="'goalTypes'">
          <tab-goal-types [companyId]="companyId" [budgetId]="selectedBudget?.id"></tab-goal-types>
        </div>
        <div *ngSwitchCase="'vendors'">
          <tab-vendors [companyId]="companyId"></tab-vendors>
        </div>
        <div *ngSwitchCase="'glCodes'">
          <tab-gl-codes [companyId]="companyId"></tab-gl-codes>
        </div>
        <div *ngSwitchCase="'tags'">
          <tab-tags [companyId]="companyId"></tab-tags>
        </div>
      </div>

    </div>
  </div>

</div>

<budget-selection-attributes-tags></budget-selection-attributes-tags>