import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, map, of, switchMap } from 'rxjs';
import { getDiff } from 'recursive-diff';
import { getRequestOptions } from '@shared/utils/http-request.utils';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { FormGroup } from '@angular/forms';

export interface CustomFieldListDO {
  id: number;
  name: string;
  is_mandatory: boolean;
  type: string;
  strict_inheritance: boolean;
  sort_order: number;
  options: CustomFieldOptionDO[];
  selected_values: Record<string, string>[];
  description?: string;
}

export interface CustomFieldListResponse {
  custom_fields: CustomFieldListDO[];
}

export interface CustomFieldStateConfig {
  id: number;
  cfName: string;
  defaultValue?: string | string[];
  options: string[];
  isMultiSelect: boolean;
  required: boolean;
  selectedValue: string | string[];
  optionValueIdMapping: Record<string, CustomFieldOptionDO>;
  description?: string;
  allowNoSelection?: boolean;
}

export interface CustomFieldOptionDO { 
  id: number;
  option_value: string;
  is_default: boolean;
  sort_order: number;
}

export interface CustomFieldStatusAndCountDO {
  isCFEnabledForCampaign: boolean;
  isCFEnabledForProgram: boolean;
  isCFEnabledForExpense: boolean;
  isCustomFieldEnabled: boolean;
  cfCamapaignCount: number;
  cfProgramCount: number;
  cfExpenseCount: number;
}

export interface CustomFieldStatusReponsePayload {
  is_enabled: boolean;
  cf_campaign_count: number;
  cf_program_count: number;
  cf_expense_count: number;
}

export interface CFCampaignDetailsContext {
   isCFEnabledForCampaign: boolean,
   customFieldsStateDiff: Record<number, string[]>
}

export interface CFProgramDetailsContext {
  isCFEnabledForProgram: boolean,
  customFieldsStateDiff: Record<number, string[]>
}

export interface CFExpenseDetailsContext {
  isCFEnabledForExpense: boolean,
  customFieldsStateDiff: Record<number, string[]>
}

@Injectable({
  providedIn: 'root'
})
export class CustomFieldsService {
  private readonly apiV2Url = inject(API_V2_URL);
  customFieldsStatusAndCount: BehaviorSubject<CustomFieldStatusAndCountDO> = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient) {}

  fetchDropdownOptions(companyId: number, map_id: number, mapping_type: string) {    
    return this.http.get(this.apiV2Url + 'custom_fields/consumer_cf_list/', getRequestOptions({
      company: companyId, map_id,mapping_type
    })).pipe(
      switchMap((response: CustomFieldListResponse) => {
        return of(this.convertCFListToStateConfig(response));
      })
    );
  }

  getStateDiff(prevCFState: any, currentCFState: any, excludeKeys: string[] = []): Record<string, any>{
    let diffMap = {};
    let diffResults = getDiff(prevCFState, currentCFState)
    
    diffResults.forEach(({ op, path, val }) => {
      const stateKey = path[0] as string;
      if (!excludeKeys.includes(stateKey)) {
        diffMap[stateKey] = currentCFState[stateKey];
      }
    });
    return diffMap;
  }

  convertCFListToStateConfig(responseData: CustomFieldListResponse) {
    
  const configData = responseData.custom_fields.map(
    cf => {
        let defaultOptions = cf.options.filter(o => o.is_default);
        let defaultValue;
        if(defaultOptions.length) {
            const defaultValueForCF =  defaultOptions[0].option_value;
            defaultValue = cf.type === 'Multi' ? [ defaultValueForCF ] : defaultValueForCF;
        }
        const optionValueIdMapping = cf.options.reduce((acc, op) => {
          return { ...acc, [op.option_value]: op  }
      }, {});
      
      let selectedOptionValue;
      let selectedValues = cf.selected_values.flatMap(v => Object.values(v));
      if(cf.type === 'Multi') {
        selectedOptionValue = selectedValues
      }else {
        selectedOptionValue = selectedValues.length ? selectedValues[0] : undefined;
      }
        return ({
            id: cf.id,
            cfName: cf.name,
            defaultValue: defaultValue,
            options: cf.options.map(o => o.option_value),
            isMultiSelect: cf.type === 'Multi',
            required: cf.is_mandatory,
            selectedValue: selectedOptionValue,
            optionValueIdMapping: optionValueIdMapping,
            description: cf.description,
            allowNoSelection: cf.type !== 'Multi' && !cf.is_mandatory
        })
    }
  );

  return configData;
  }

  private processCustomFieldData(data: CustomFieldStatusReponsePayload): CustomFieldStatusAndCountDO {
    return {
      isCustomFieldEnabled: data.is_enabled,
      isCFEnabledForCampaign: data.is_enabled && data.cf_campaign_count > 0,
      isCFEnabledForProgram: data.is_enabled && data.cf_program_count > 0,
      isCFEnabledForExpense: data.is_enabled && data.cf_expense_count > 0,
      cfCamapaignCount: data.cf_campaign_count,
      cfProgramCount: data.cf_program_count,
      cfExpenseCount: data.cf_expense_count
    };
  }  

  fetchCustomFieldStatusForSelectedCompany(companyId: number) {
    this.http.get(`${this.apiV2Url}` + 'custom_fields/status/', getRequestOptions({ company: companyId })).pipe(
      catchError(error => {
        console.error(`Failed to fetch CF status for Company ${companyId}: `, error);
        return of(null);
      })
    ).subscribe((data: CustomFieldStatusReponsePayload) => {
      const customFieldStatusAndCountDO = this.processCustomFieldData(data);
      this.customFieldsStatusAndCount.next(customFieldStatusAndCountDO);
    })
  }

  getCFStatus(): Observable<CustomFieldStatusAndCountDO> {
    return this.customFieldsStatusAndCount.asObservable()
  }

  getCFStatusForActivationGuard(companyId: number): Observable<CustomFieldStatusAndCountDO | null> {
    return this.http.get(`${this.apiV2Url}custom_fields/status/`, { params: { company: companyId.toString() } }).pipe(
      map((data: CustomFieldStatusReponsePayload) => {
        const customFieldStatusAndCountDO = this.processCustomFieldData(data);
        this.customFieldsStatusAndCount.next(customFieldStatusAndCountDO);
        return customFieldStatusAndCountDO;
      }),
      catchError(error => {
        console.error(`Failed to fetch CF status for Company ${companyId}: `, error);
        this.customFieldsStatusAndCount.next(null);
        return of(null);
      })
    );
  }

  getSelectedOptionsIdPayloadForCF(diffMap: Record<string, any>, customFieldConfigs: CustomFieldStateConfig[]) {

    let data: Record<number, string[]> = {};
    if(customFieldConfigs.length === 0) {
      return {};
    }
    Object.keys(diffMap).forEach(k => {
      let configIndex = k.split('_')[1];
      let optionValueKeys = [diffMap[k]].flat();
      let currentCFConfig = customFieldConfigs[configIndex]
      let val = optionValueKeys.reduce((acc, key) => {
        return [...acc, currentCFConfig.optionValueIdMapping[key]];
      }, []);
      // Filtering out null from single select options signifying no selection 
      data = { ...data, [currentCFConfig.id]: [val].flat().map(v => v?.id || null).filter(id => id !== null) }
    });
   
    return data;
  }

  initDefaultCustomFieldsFormData(objectId: number, form: FormGroup<any>,) {
    // This signifies creation of new artifact CG, EG, Expense
    if(!objectId) {
      let initialFormState = form.getRawValue();
      let initialDropdownState: Record<string, any[]> = {}
      for(let key in initialFormState) {
        initialDropdownState[key] = Array.isArray(initialFormState[key]) ? []:  null;
      }
      return initialDropdownState;
    }
    return null;
  }

  rearrangeSelectedOptionValues(selectedValues, optionValues) {
    // Filter out the selectedValues from optionValues
    const nonSelectedValues = optionValues.filter(id => !selectedValues.includes(id));
    
    // Combine the selectedValues followed by the nonSelectedValues
    return [...selectedValues, ...nonSelectedValues];
  }

}