import { inject, Injectable } from '@angular/core';
import { API_V3_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'  // Makes the service available globally
})
export class PfmV3Service extends BaseApiService {
  protected apiUrl: string = inject(API_V3_URL);
}
